import React, { useEffect, useState } from 'react';
import '../../assets/mobile/css/style.css';
import cloud from '../../assets/mobile/images/cloud-icon.svg';
import pic from '../../assets/mobile/images/pic-icon.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetIdDocumentStatusQuery } from '../../services/identificationApi';
import { token, trnId } from '../../store/registration/logindSlice';
import { useDispatch } from 'react-redux';

const ScanHome = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const { data, isLoading } = useGetIdDocumentStatusQuery(id);
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    if (searchParams.get("token")) {
        dispatch(token(searchParams.get("token")));
        dispatch(trnId(id));
    }
    const [startBtnState, setStartBtnState] = useState(false);

    useEffect(() => {
         if (data?.records?.front?.document_typeid == "2" || data?.records?.back?.document_typeid == "13") {
            if (data?.records?.front?.res && !data?.records?.back?.res) {
                setStartBtnState(false);
            }
            else if (!data?.records?.front?.res && data?.records?.back?.res) {
                setStartBtnState(true);
            }else{
                setStartBtnState(true);
            }
        } else {
            if(!data?.records?.front?.res){
                 setStartBtnState(false);
            }else
            setStartBtnState(true);
        }
    }, [data])
    return (
        <>
            <section className="screen-wrap">
                <div className="black-title blue-title">
                    <h1> &nbsp; &nbsp; Welcome to Docuverus</h1>
                </div>
                <div className="app-body">
                    <div className="ver-wrap">
                       {!startBtnState && <> <h3 className="page-title text-center mr-b10">Let’s verify your identity</h3>
                        <h5 className="page-sub-title text-center">Select the types of document you would to upload</h5>
                        </>}
                        {startBtnState && <>
                        <h3 className="page-title text-center mr-b10" style={{color:'red'}} >Your documents have already been uploaded</h3>
                        <h5 className="page-sub-title text-center">Please login to your application and delete them to upload new documents</h5>
                        {(!data?.records?.front?.res && data?.records?.back?.res) && <h5 className="page-sub-title text-center">Please delete back side driver's license to upload new documents</h5>}
                        </>}
                       {!startBtnState && <div className="road-map-wrap mr-t30">
                            <ul>
                                <li>    
                                    <div className="map-icon">
                                        <img src={pic} className="cir-ani" alt="" title="" />
                                    </div>
                                    <div className="map-info">
                                        <h5>
                                            Take Picture of an identity <br />
                                            proof Documents
                                        </h5>
                                    </div>
                                    <div className="divaider"></div>
                                </li>
                                <li>
                                    <div className="map-icon">
                                        <img src={cloud} className="cir-ani" alt="" title="" />
                                    </div>
                                    <div className="map-info">
                                        <h5>
                                            Upload Different types<br />
                                            of documents
                                        </h5>
                                    </div>
                                    <div className="divaider"></div>
                                </li>
                            </ul>
                        </div>}
                    </div>
                    {!startBtnState && <div className="app-foot text-center">
                        <button type="button" onClick={() => {
                            if(data?.records?.front?.res && !data?.records?.back?.res){
                                navigate(`back/${data?.records?.front?.document_typeid}`, { replace: true })
                                return ;
                            }else
                            navigate('menu', { replace: true })

                        }} className="blue-btn btn-disable mr-b10">Start</button>
                    </div>}
                </div>
            </section>
        </>
    );
};

export default ScanHome;