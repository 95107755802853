import React from "react";
import { useFormContext, useController } from "react-hook-form";

const InputCheckBoxField = (props) => {
  const { name } = props.register;
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <div className="cus-check">
        <input
          disabled={props.disabled || false}
          type="checkbox"
          className={`form-control cus-input-control ${
            error !== undefined || (required && !field.value && "is-invalid")
          }`}
          {...field}
          id={name}
          checked={props.checked ? props.checked : ""}
        />
        <label
          htmlFor={name}
          className={`mr-b0 d-flex align-items-center ${
            error !== undefined || (required && !field.value && "text-danger")
          }`}
        >
          <span className="mr-r10"></span>
          {props.label}
        </label>
        <div className="invalid-feedback">
          {error !== undefined && error.message}
        </div>
      </div>
    </>
  );
};

export default InputCheckBoxField;
