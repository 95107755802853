// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API, BASE_URL_LOCAL } from "../config";
import { logout, token } from "../store/registration/logindSlice";

//const BASE_URL = "https://40.71.253.227 ";
const BASE_URL = BASE_URL_API;
// const BASE_URL = "https://api.ats.dvapply.com";

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const {
      persistedReducer: {
        loginSlice: { email, token,trnId },
      },
    } = getState();
    const accessToken = token;
    if (accessToken) {
      headers.set("email", email);
      headers.set("trn_id", trnId);
      headers.set("authorization", `${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const {persistedReducer:{loginSlice:{refreshToken}}} = api.getState();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 406){
    alert(result.error.data.message);
    api.dispatch(logout(true));
    return ;
  } 
  if (result.error && result.error.status === 401) {
    const refreshResult = await fetch(BASE_URL.split("/api")[0]+"/api/auth/refreshtoken",{
        method:'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
        body:JSON.stringify({'refreshtoken':refreshToken})
      }
    );
    if (refreshResult.ok) {
      const data= await refreshResult.json();
      api.dispatch(token(data.data.tokendata));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
       api.dispatch(logout(true));
    }
  }
  return result;
};

export const identificationApi = createApi({
  reducerPath: "identificationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "ID",
    "DELETEDOC",
    "ID1",
    "CONTEXT",
    "incomeBank",
    "APPLICANTSTATUS",
    "SECTIONSTATUS",
    "SAVECONTEXT",
    "GETUPDATEADDRESS",
    "SECONDARYDOC",
    "APPLICATIONSTATUS",
    "GETUPDATEFLOORUNITS",
  ],
  endpoints: (builder) => ({
    getIncomeBanking: builder.query({
      query: (trn_id) => ({
        url: `/income/getIncomebanking/` + trn_id,
      }),
      providesTags: ["incomeBank", "DELETEDOC"],
      transformResponse: (response) => {
        let employerStatusOptions = [
          {
            label: "Employed Full-Time",
            value: "Employed Full-Time",
          },
          {
            label: "Employed Part-Time",
            value: "Employed Part-Time",
          },
        ];
        const addKeyToEmployer =
          response.records.employment_details.employer.map((item) => {
            let updatedEmployerStatus;
            if (item?.employer_status !== "") {
              updatedEmployerStatus =
                item?.employer_status == "Employed Full-Time"
                  ? employerStatusOptions[0]
                  : employerStatusOptions[1];
            }
            return {
              ...item,
              employer_status: updatedEmployerStatus,
              availableToUpload:
                item.files.length > 0
                  ? "Yes"
                  : item.no_doc_reason == null
                    ? ""
                    : "No",
              no_doc_reason:
                item.no_doc_reason ||
                "I don't currently have access to document(s), but can provide later",
              fileCount: item.files.length,
              pay_frequency: item.pay_frequency &&
                item.pay_frequency !== "null" && {
                label: item.pay_frequency,
                value: item.pay_frequency,
              },
              gross_pay_verify: false,
              actual_state: response?.records?.states?.filter(
                (singleState, index) => {
                  console.log("SingleState, item", singleState, item);
                  if (item?.employer_state == singleState.StateShortCode) {
                    return {
                      value: singleState.ID || index,
                      label:
                        singleState.StateShortCode +
                        " - " +
                        singleState.StateName,
                    };
                  }
                }
              ),
            };
          });

        const addKeyToOtherEmployer =
          response.records.other_income_details.otherincome.map((item) => {
            return {
              ...item,
              availableToUpload:
                item.files.length > 0
                  ? "Yes"
                  : item.no_doc_reason != null
                    ? "No"
                    : "",
              no_doc_reason: item.no_doc_reason || "I don't currently have access to document(s), but can provide later",
              fileCount: item.files.length,
              other_income_type: item.other_income_type && {
                label: item.other_income_type,
                value: item.other_income_type,
              },
              rental_assistance_type: item.rental_assistance_type && {
                label: item.rental_assistance_type,
                value: item.rental_assistance_type,
              },
            };
          });
        const addKeyToBank = response.records.banking_details.banking.map(
          (item) => {
            return {
              ...item,
              availableToUpload:
                item.files.length > 0
                  ? "Yes"
                  : item.no_doc_reason != null
                    ? "No"
                    : "",
              no_doc_reason: item.files.length > 0 ? null : item.no_doc_reason || "I don't currently have access to document(s), but can provide later",
              fileCount: item.files.length,
              account_type: item.account_type && {
                label: item.account_type,
                value: item.account_type,
              },
              other_reason: item.other_reason,
            };
          }
        );
        return {
          ...response.records,
          employment_details: {
            ...response.records.employment_details,
            no_doc_reason:
              response.records.employment_details.no_doc_reason || "I am not currently employed",
            employerCount: response.records.employment_details.employer.length,
            employer: addKeyToEmployer?.map((singleEmp, index) => {
              if (singleEmp?.actual_state?.length > 0) {
                let stateBody = {
                  value: singleEmp?.actual_state[0]?.ID || index,
                  label:
                    singleEmp?.actual_state[0]?.StateShortCode +
                    " - " +
                    singleEmp?.actual_state[0]?.StateName,
                };
                return {
                  ...singleEmp,
                  employer_state: stateBody,
                };
              } else {
                return {
                  ...singleEmp,
                  iscurrent_employer:
                    index == 0
                      ? singleEmp.iscurrent_employer || true
                      : singleEmp.iscurrent_employer,
                };
              }
            }),
          },

          other_income_details: {
            ...response.records.other_income_details,
            employerCount:
              response.records.other_income_details.otherincome.length,
            otherincome: addKeyToOtherEmployer,
          },
          banking_details: {
            ...response.records.banking_details,
            employerCount: response.records.banking_details.banking.length,
            banking: addKeyToBank,
          },
        };
      },
    }),
    getCompanyLogo: builder.query({
      query: (body) => ({
        url: `/users/logo/2`,
      }),
    }),
    getApplicantStatus: builder.query({
      query: (trn_id) => ({
        url: `/users/successstatus/` + trn_id,
      }),
      transformResponse: (response) => {
        return {
          ...response,
          applicant_status: response.records,
        };
      },
      providesTags: ["APPLICATIONSTATUS"],
    }),
    getTermandCondtion: builder.query({
      query: (trn_id) => ({
        url: `/stripe/getTandCpageforpayment/` + trn_id,
      })
    }),
    getTermandCondtionRegistration: builder.query({
      query: (trn_id) => ({
        url: `/users/getTandCpageforregistration/` + trn_id,
      })
    }),
    addEmployerSource: builder.mutation({
      query: (body) => {
        return {
          url: "income/addSource",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["incomeBank"],
    }),
    uploadFileService: builder.mutation({
      query: (body) => {
        return {
          url: "/applicant/document",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: [
        "ID",
        "incomeBank",
        "CONTEXT",
        "SECONDARYDOC",
        "APPLICATIONSTATUS",
      ],
    }),

    // we need to add trn id to url alwas to get all data intact
    getIdentificationData: builder.query({
      query: (trn_id) => ({
        url: `/applicant/getidentification/${trn_id}`,
      }),
      providesTags: ["ID", "DELETEDOC", "ID1"],
      transformResponse: async (response) => {
        const stateId = response.records.identification_data.state;
        const selectedState = response.records.states.filter((state) => {
          return state.ID == stateId;
        });
        if (response.records?.address?.length > 0) {
          const updateAddress = await response.records?.address.map(
            async (singleAddress, index) => {
              let states = await response.records.states.filter((state) => {
                if (state.ID == singleAddress.state) {
                  console.log("Check State", state);
                  let body = {
                    value: state.ID,
                    label: state.StateName,
                  };
                  console.log("State Body", body);
                  singleAddress.state = body;
                }
              });
              console.log("Check The Updated Address", singleAddress);
            }
          );
        }
        
        const res = {
          ...response.records,
          applicant: {
            ...response.records.applicant,
            otherPhoneCaption: {
              value: response.records.applicant.otherPhoneCaption,
              label: response.records.applicant.otherPhoneCaption,
            },
            alias_status:
              response.records.applicant?.Alias?.length > 0
                ? "Yes"
                : "No",
            // no_doc_reason: !response.records.identification_data.other_reason
            //   ? "Other"
            //   : !!response.records.identification_data.no_doc_reason
            //   ? response.records.identification_data.no_doc_reason
            //   : "I don't currently have access to document(s), but can provide later",
            // no_doc_reason:
            //   response.records.identification_data.no_doc_reason ||
            //   "I don't currently have access to document(s), but can provide later",
            availableToUpload_id:
              response.records.identification_data.availableToUpload_id,
            state:
              selectedState?.length > 0
                ? {
                  value: selectedState[0].ID,
                  label:
                    selectedState[0].StateShortCode +
                    " - " +
                    selectedState[0].StateName,
                }
                : null,
            isNameMatched: "",
            isDOBMatched: "",
          },
          address: !response.records.address.length
            ? [
              {
                id: 0,
                is_current_address: true,
                move_in_date: null,
                move_out_date: null,
              },
            ]
            : response.records.address,
          ssn_data: {
            ...response.records.ssn_data,
            availableToUpload_ssn: response.records.ssn_data.file
              ? "Yes"
              : response.records.ssn_data.no_doc_reason
                ? "No"
                : response.records.ssn_data.other_reason
                  ? "No"
                  : "",
            // no_doc_reason: !response.records.ssn_data.other_reason
            //   ? "Other"
            //   : !!response.records.ssn_data.no_doc_reason
            //   ? response.records.ssn_data.no_doc_reason
            //   : "I don't currently have access to document(s), but can provide later",
            // assign_no_doc_reason: !response.records.ssn_data.other_reason
            //   ? "Other"
            //   : !!response.records.ssn_data.no_doc_reason
            no_doc_reason: response.records.ssn_data.no_doc_reason || "I don't currently have access to document(s), but can provide later",
          },
          identification_data:{
            ...response.records.identification_data,
            no_doc_reason: response.records.identification_data.no_doc_reason || "I don't currently have access to document(s), but can provide later",
          }
        };
        return res;
      },
    }),
    saveIdDetails: builder.mutation({
      query: (body) => {
        return {
          url: "/applicant/saveiddetail",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["ID1", "SECTIONSTATUS"],
    }),

    // addToReviewQ: builder.mutation({
    //   query: (body) => {
    //     return {
    //       url: "/document/updateApplicationStatus",
    //       method: "POST",
    //       body: body,
    //     };
    //   },
    //   // invalidatesTags: ["ID1", "SECTIONSTATUS"],
    // }),
    savePayslip: builder.mutation({
      query: (body) => {
        const newBody = {
          ...body, employment_details: {
            ...body.employment_details,
            no_doc_reason: body.employment_details.employer.length == 0 ? body.employment_details.no_doc_reason : null
          }
        }
        return {
          url: "/income/saveincomebanking",
          method: "POST",
          body: newBody,
        };
      },
      invalidatesTags: ["incomeBank", "SECTIONSTATUS"],
    }),
    deleteDocument: builder.mutation({
      query: (body) => {
        return {
          url: `/applicant/deletedocument/${body}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DELETEDOC", "SECONDARYDOC", "APPLICATIONSTATUS"],
    }),
    deleteSource: builder.mutation({
      query: (body) => {
        return {
          url: `income/deleteSource`,
          method: "DELETE",
          body: body,
        };
      },
      invalidatesTags: ["DELETEDOC", "APPLICATIONSTATUS"],
    }),
    getDetails: builder.query({
      query: (trn_id) => ({
        url: `/contextual/getdetails/${trn_id}`,
      }),
      providesTags: ["CONTEXT"],
      invalidatesTags: ["SECTIONSTATUS"],
      transformResponse: (response) => {
        let labelCount = 0;
        return {
          questionsLength: response.records?.length,
          anyone_over_18: response?.records[0]
            ? {
              ...response?.records[0],
              labelCount: response?.records[0].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          co_occupants: response?.records[1]
            ? {
              ...response?.records[1], //2
              // labelIndex: response.records[0].display ? 2 : 1,
              labelCount: response?.records[1].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          is_co_signer: response?.records[2]
            ? {
              ...response?.records[2],

              labelCount: response?.records[2].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          reference: response?.records[3]
            ? {
              ...response?.records[3],

              labelCount: response?.records[3].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          emergency_reference: response?.records[4]
            ? {
              ...response?.records[4],

              labelCount: response?.records[4].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          renters_insurance: response?.records[8]
            ? {
              ...response?.records[8],

              labelCount: response?.records[8].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          eviction_or_nonpayment: response?.records[10]
            ? {
              ...response?.records[10],

              labelCount: response?.records[10].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {}, //TODO: From Contengies
          contingencies: response?.records[11]
            ? {
              ...response?.records[11],

              labelCount: response?.records[11].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          filed_for_bankruptcy: response?.records[13]
            ? {
              ...response?.records[13],

              labelCount: response?.records[13].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          is_student: response?.records[7]
            ? {
              ...response?.records[7],

              labelCount: response?.records[7].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          have_vehicle: response?.records[6]
            ? {
              ...response?.records[6],

              labelCount: response?.records[6].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          have_pet: response?.records[5]
            ? {
              ...response?.records[5],

              labelCount: response?.records[5].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
          do_you_smoke: response?.records[12]
            ? {
              ...response?.records[12],

              labelCount: response?.records[12].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},

          convicted_for_crime: response?.records[9]
            ? {
              ...response?.records[9],

              labelCount: response?.records[9].display
                ? labelCount++
                : labelCount,
              labelIndex: labelCount,
            } || {
              display: false,
              required: false,
              response: "no",
            }
            : {},
        };
      },
    }),
    saveContexttual: builder.mutation({
      query: (body) => {
        return {
          url: "/contextual/savedetails",
          method: "POST",
          body: body,
        };
      },
      providesTags: ["SAVECONTEXT"],
      invalidatesTags: ["CONTEXT", "SECTIONSTATUS"],
    }),
    savePayment: builder.mutation({
      query: (body) => {
        return {
          url: "/stripe/payment",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SECTIONSTATUS"],
    }),
    updatereviewpending: builder.mutation({
      query: (body) => {
        return {
          url: "/users/updatereviewpending",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SECTIONSTATUS"],
    }),
    getSectionStatus: builder.query({
      query: (trn_id) => ({
        url: `/users/sectionstatus/${trn_id}`,
      }),
      providesTags: ["SECTIONSTATUS"],
      transformResponse: (response) => {
        console.log("Check Response for section status", response);
        return response.records;
      },
    }),
    getViewConsent: builder.query({
      query: (trn_id) => ({
        url: `/stripe/getpayment/${trn_id}`,
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
    getIdDocumentStatus: builder.query({
      query: (trn_id) => ({
        url: `/applicant/getiddocumentstatus/${trn_id}`,
      }),
      providesTags: ["DOCUMENTSTATUS"],
    }),
    mobileScanSave: builder.mutation({
      query: (body) => {
        return {
          url: "/applicant/mobile_scan_saveonly",
          method: "POST",
          body: body,
        };
      },
    }),
    getUpdatedDesiredAddress: builder.query({
      query: (trn_id) => ({
        url: `/applicant/getupdateddesiredaddress/${trn_id}`,
      }),
      providesTags: ["GETUPDATEADDRESS"],
      transformResponse: async (response) => {
        console.log("Check Address Response", response);
        const state = await response?.records?.state.map((item, index) => {
          return {
            value: item.ID || index,
            label: item.StateShortCode + " - " + item.StateName,
          };
        });
        let shortCodeUserState =
          response?.records?.result?.recordset[0]?.State.split(" ")[0];
        let applicantState = await response?.records?.state.filter(
          (state, index) => {
            if (shortCodeUserState == state.StateShortCode) {
              const body = {
                value: state.ID,
                label: state.StateName,
              };

              console.log("Check State", body);

              return {
                value: state.ID,
                label: state.StateName,
              };
            }
          }
        );
        console.log("Check Applicant State", applicantState);
        return {
          ...response.records,
          state: state,
          records: {
            ...response.records,
            applicantState,
          },
        };
      },
    }),
    updateDesiredAddess: builder.mutation({
      query: (body) => {
        return {
          url: "/applicant/updatedesiredaddress",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["GETUPDATEADDRESS"],
    }),
    getSecondaryAppDetails: builder.query({
      query: (trn_id) => ({
        url: `/contextual/getsecondaryapplicationdetails/${trn_id}`,
      }),

      transformResponse: (response) => {
        return {
          convicted_for_crime: response.records[0],
          convictions_on_property: response.records[1],
          proof_of_document: response.records[2],
        };
      },
      providesTags: ["SECONDARYDOC"],
    }),
    saveSecondaryApp: builder.mutation({
      query: (body) => {
        return {
          url: "/contextual/savesecondaryapplicationdetails",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SECONDARYDOC", "APPLICATIONSTATUS"],
    }),
    getDocumentImg: builder.query({
      query: (img_id) => ({
        url: `/applicant/getdocumentImg/${img_id}`,
        // responseType: 'blob',
      }),
    }),
    getSubscriberFees: builder.query({
      query: (trn_id) => ({
        url: `/stripe/payment/getSubscriberFees/${trn_id}`,
      }),
    }),

    updateFloorPlanUnit: builder.mutation({
      query: (body) => {
        return {
          url: "/users/updateFloorPlanUnit",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["GETUPDATEFLOORUNITS"],
    }),

    getUpdatedFloorsAndUnit: builder.query({
      query: (trn_id) => ({
        url: `/users/getUpdatedFloorsandUnit/${trn_id}`,
      }),
      providesTags: ["GETUPDATEFLOORUNITS"],
    }),
  }),
});

export const {
  useGetCompanyLogoQuery,
  useUploadFileServiceMutation,
  useGetIdentificationDataQuery,
  useSaveIdDetailsMutation,
  //useAddToReviewQMutation,
  useDeleteDocumentMutation,
  useGetDetailsQuery,
  useSaveContexttualMutation,
  useGetIncomeBankingQuery,
  useDeleteSourceMutation,
  useSavePayslipMutation,
  useAddEmployerSourceMutation,
  useGetApplicantStatusQuery,
  useSavePaymentMutation,
  useUpdatereviewpendingMutation,
  useGetSectionStatusQuery,
  useGetViewConsentQuery,
  useGetUpdatedDesiredAddressQuery,
  useUpdateDesiredAddessMutation,
  useGetSecondaryAppDetailsQuery,
  useSaveSecondaryAppMutation,
  useGetDocumentImgQuery,
  useGetIdDocumentStatusQuery,
  useMobileScanSaveMutation,
  useGetSubscriberFeesQuery,
  useUpdateFloorPlanUnitMutation,
  useGetUpdatedFloorsAndUnitQuery,
  useGetTermandCondtionQuery,
  useGetTermandCondtionRegistrationQuery,
} = identificationApi;

export default identificationApi;
