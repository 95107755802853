import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// import { combineReducers, createStore } from "redux";
// import registrationSideMenuReducers from "./reducers/registrationSideMenuReducer";
import registration from "./registration";
import identification from "./identification";
import { registrationApi } from "../services/registrationApi";
import { identificationApi } from "../services/identificationApi";
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session' // defaults to localStorage for web
import formSubmit from '../store/registration/fromSubmitSlice';
import mlApi from "../services/mlApi";
// const store=createStore(combineReducers({
//    registrationSideMenuReducers
// }), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// export default store;


const persistConfig = {
  key: 'root',
  storage:storageSession,
}
const persistedReducer = persistReducer(persistConfig, registration);

const store = configureStore({
  reducer: {
    persistedReducer,
    identification,
    formSubmit,
    [registrationApi.reducerPath]: registrationApi.reducer,
    [identificationApi.reducerPath]: identificationApi.reducer,
    [mlApi.reducerPath]: mlApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(registrationApi.middleware, identificationApi.middleware,mlApi.middleware),
});

export const  persister = persistStore(store);

setupListeners(store.dispatch);
export default store;
