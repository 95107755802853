/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SuperHeader from "../../components/common/superHeader/superHeader";
import {
  useAcceptTermsAndConditionsMutation,
  useGetMasterRegistrationDataQuery,
} from "../../services/registrationApi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputCheckBoxField from "../../components/registration/Inputs/InputCheckBoxField";
import { toast, ToastContainer } from "react-toastify";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import { BsCheck2Circle } from "react-icons/bs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import visa from "../../assets/images/visa.png";
import amex from "../../assets/images/amex.png";
import discover from "../../assets/images/discover.png";
import mc from "../../assets/images/mc.png";
import { AiOutlinePlus } from "react-icons/ai";
import { protocol } from "../../config";
import SupportSection from "../../components/common/SupportSection/SupportSection";
import parse from 'html-react-parser';
import { useGetTermandCondtionQuery, useGetTermandCondtionRegistrationQuery } from "../../services/identificationApi";

const Instruction = (props) => {

  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const navigate = useNavigate();
  const schema = yup.object().shape({
    // i_accept: yup.bool().oneOf([true], "field must be checked"),
  });
  const methods = useForm({
    defaultValues: {
      i_accept: false,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { register, handleSubmit } = methods;

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const { data, isLoading, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });

  const registerTermsData = useGetTermandCondtionRegistrationQuery(trn_id);
  const { data: dataTerm } = useGetTermandCondtionQuery(trn_id);

  const [acceptTermsAndConditions] = useAcceptTermsAndConditionsMutation();
  const [selectedTab, setSelectedTab] = React.useState("Instruction");
  const [openInstruction, setOpenInstruction] = React.useState(true);
  const [openTerms, setOpenTerms] = React.useState(false);
  const [clickHere, setClickHere] = React.useState(true);
  const onSubmit = async (formData) => {
    const response = await acceptTermsAndConditions(trn_id);
    console.log("Response for instruction", response);
    if (response.data?.success === true) {
      toast.success(response.data.message, { pauseOnFocusLoss: false });
      navigate("/identification");
    }
    if (response.data?.success === false) {
      toast.error(response.data.message, { pauseOnFocusLoss: false });
    }
  };

  React.useEffect(() => {
    document.title = "Terms and Condition";
  }, []);
  return (
    <main className="body-wrap">
      <SuperHeader />
      <section className="top-white-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-sm-6">
              <div className="w-r-logo">
                <img
                  src={`${data?.records?.subrciber_logo}`}
                  className="w-r-logo-img"
                  alt=""
                  title=""
                />
                <span className="res-company-name">
                  {data?.records?.subscriber_name}
                </span>
              </div>
            </div>
            <div className="col col-sm-6">
              <div
                className="t-w-appid text-right d-flex"
                style={{ justifyContent: "end" }}
              >
                <h5 className="mr-r10">
                  Name:{" "}
                  <span>
                    {" "}
                    {data?.records?.first_name} {data?.records?.last_name}
                  </span>
                </h5>
                <h5>
                  Application ID:{" "}
                  <span>{data?.records?.application_number}</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="application-body-wrap">
        <div className="container">
          <div className="row">
            <div
              className={`${useWindowWidth.windowWidth < 766
                ? "d-none"
                : "col-sm-4 col-md-3 col-lg-3"
                }`}
            >
              <div className="tabs-nav-block white-box xs-tabs-btn">
                <div className="s-tab-list">
                  <ul
                    className="nav nav-tabs flex-column"
                    id="myTab"
                    role="tablist"
                  >
                    <li
                      className={`nav-item m-b-5 ${selectedTab === "Instruction" ? "site-active" : ""
                        }`}
                      role="presentation"
                    >
                      <a
                        className="nav-link active"
                        id="instructions-tab"
                        data-toggle="tab"
                        href="#instructions"
                        role="tab"
                        aria-controls="instructions"
                        aria-selected="true"
                        onClick={() => {
                          setSelectedTab("Instruction");
                          setOpenTerms(false);
                          setOpenInstruction(true);
                        }}
                      >
                        Instructions
                      </a>
                    </li>
                    <li
                      className={`nav-item ${selectedTab === "Terms" ? "site-active" : ""
                        }`}
                      role="presentation"
                    >
                      <a
                        className="nav-link active"
                        id="tc-tab"
                        data-toggle="tab"
                        href="#tc"
                        role="tab"
                        aria-controls="tc"
                        aria-selected="false"
                        onClick={() => {
                          setSelectedTab("Terms");
                          setOpenInstruction(false);
                          setOpenTerms(true);
                        }}
                      >
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-8 col-md-9 col-lg-9 ">
              <Accordion
                expanded={openInstruction}
                onChange={() => {
                  setOpenInstruction(!openInstruction);
                  setOpenTerms(!openTerms);
                }}
              >
                <AccordionSummary
                  expandIcon={!openInstruction && <AiOutlinePlus />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    {openInstruction && <BsCheck2Circle color="green" />}{" "}
                    Instructions
                  </Typography>
                </AccordionSummary>
                <div className="static-info ">
                  <div className="container ">
                    {registerTermsData?.data?.records?.text && (<>
                      {parse(registerTermsData?.data?.records?.text)}
                    </>)}
                    {registerTermsData?.data?.records?.subscriber_text && (<>
                      <br />
                      {parse(registerTermsData?.data?.records?.subscriber_text)}
                      <br />
                    </>)}
                    <br />
                  </div>
                  {/* <div className="container ">
                    <div className="row">
                      <div className=" col-sm-6 col-md-6 col-lg-6">
                        <div className="">
                          <ul>
                            <li>Visa</li>
                            <li>MasterCard</li>
                            <li>Discover</li>
                            <li>American Express</li>
                          </ul>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-md-6 col-lg-6">
                        <div className="d-flex justify-content-end">
                          <img
                            src={visa}
                            style={{
                              width: "80px",
                              height: "auto",
                              margin: "2px",
                            }}
                            alt=""
                          />
                          <img
                            src={mc}
                            style={{
                              width: "80px",
                              height: "auto",
                              margin: "2px",
                            }}
                            alt=""
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <img
                            src={discover}
                            style={{
                              width: "80px",
                              height: "auto",
                              margin: "2px",
                            }}
                            alt=""
                          />
                          <img
                            src={amex}
                            style={{
                              width: "80px",
                              height: "auto",
                              margin: "2px",
                            }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Accordion>
              <Accordion
                expanded={openTerms}
                onChange={() => {
                  setOpenTerms(!openTerms);
                  setOpenInstruction(!openInstruction);
                }}
              >
                <AccordionSummary
                  expandIcon={!openTerms && <AiOutlinePlus />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    {openTerms && <BsCheck2Circle color="green" />} Terms &
                    Conditions
                  </Typography>
                </AccordionSummary>
                <div className="static-info">
                  <div className="container">
                    {dataTerm?.records?.text && (<>
                      {parse(dataTerm?.records?.text)}
                    </>)}

                    {dataTerm?.records?.subscriber_text && (<>
                      <br />
                      {parse(dataTerm?.records?.subscriber_text)}
                    </>)}
                    <br />
                    <br />
                    {/* <p className="para-format">
                     
                      Docuverus charges a NON-REFU NDABLE APPLICATION FEE of
                      $5.00 per applicant (the "Application Fee"), which covers
                      certain processing and screening service costs associated
                      with your application submitted herein (the
                      "Application"), such as verifying your income, checking
                      your credit, and conducting a background check. Under no
                      circumstances shall Applicant be entitled to a refund or
                      charge-back of the Application Fee. The Application Fee,
                      which must be paid at the time that the Applicant submits
                      a rental application, is separate and apart from any real
                      estate brokerage that Applicant may be responsible for
                      paying to a Broker or my have otherwise agreed to pay to a
                      Broker.
                    </p>
                    <p className="para-format">
                      Applicant represents and warrants that all of the
                      information contained in the Application is true and
                      accurate. Applicant expressly understands and agrees that
                      submitting this Application does not guarantee that it
                      will be accepted by the applicable landlord or property
                      management company. Applicant acknowledges and agrees that
                      Application acceptance is solely within the discretion of
                      landlord and/or property management company and neither
                      Docuverus, nor National Tenant Network (defined below), is
                      responsible for determining whether Applicant's
                      application is accepted or denied. Applicant further
                      acknowledges and agrees that landlord(s) and/or property
                      management company may deny Applicant's application or
                      terminate any lease agreement with Applicant if any
                      information contained herein is false, incomplete,
                      unverifiable, and/or misleading. Applicant shall not be
                      entitled to a refund or charge-back of the Application Fee
                      in the event that the application is rejected or denied
                      for any reason.
                    </p>
                    <p className="para-format">
                      As part of the application process, National Tenant
                      Network will conduct a background screening report, In
                      order for National Tenant Network and any prospective
                      landlord to process your Application, Applicant must
                      provide written consent to conduct or run certain
                      searches, inquiries, or reports, which may include
                      accessing your credit report (from Equifax, Experian or
                      TransUnion), eviction report, criminal background report,
                      sex offender report, income/employment verification,
                      residency verification and OFAC terrorist search. By
                      completing this Application and signing below, you consent
                      to National Tenant Network conducting such searches in
                      accordance with the Fair Credit Reporting Act. You are
                      also consenting to Docuverus sharing your Application with
                      National Tenant Network for the purpose of running a
                      background screening report.
                    </p>
                    <p className="para-format">
                      Applicant consents to Docuverus sharing all application
                      information and documents with Anytown Real Estate Ltd,
                      and Applicant also consents to Anytown Real Estate Ltd
                      providing a copy of Applicant's application and/or
                      background report to a prospective Landlord and/or his/her
                      respective listing agent, if applicable, in connection
                      with the rental property applied for.
                    </p>
                    <p className="para-format">
                      Federal and State Disclosures <br />
                      <a
                        href="https://files.consumerfinance.gov/f/documents/bcfp_consumer-rights-summary_2018-09.pdf"
                        target={"_blank"}
                      >
                        Click here
                      </a>{" "}
                      for a Summary of Your Rights Under the Fair Credit
                      Reporting Act.
                    </p>
                    <p className="para-format">
                      <p className="consent-payment-country-tags">
                        Washington State applicants only:
                      </p>{" "}
                      You also have the right to request from the consumer
                      reporting agency a written summary of your rights and
                      remedies under the Washington Fair Credit Reporting Act.
                    </p>
                    <p className="para-format">
                      <p className="consent-payment-country-tags">
                        New York/Maine applicants only:
                      </p>{" "}
                      You have the right to inspect and receive a copy of any
                      investigative consumer report requested by the Landlord by
                      contacting the consumer reporting agency identified
                      directly. You may also contact the Company to request the
                      name, address and telephone number of the nearest unit of
                      the consumer reporting agency designated to handle
                      inquiries, which the Company shall provide within 5 days.
                    </p>
                    <p className="para-format">
                      <p className="consent-payment-country-tags">
                        New York City applicants only:
                      </p>{" "}
                      Pursuant to federal, state, and local law: 1) If we take
                      adverse action against you on the basis of information
                      contained in a tenant screening report, we must notify you
                      that such action was taken and supply you with the name
                      and address of the consumer reporting agency that provided
                      the tenant screening report on the basis of which such
                      action was taken; 2) if any adverse action is taken
                      against you based on information contained in a tenant
                      screening report, you have the right to inspect and
                      receive a free copy of that report by contacting the
                      consumer reporting agency: NTN-New York, PO Box 1023,
                      Turnersville, NJ 08012, Telephone: 800-422-8299; 3) every
                      tenant or prospective tenant is entitled to one free
                      tenant screening report from each national consumer
                      reporting agency annually, in addition to a credit report
                      that should be obtained from www.annualcreditreport.com;
                      and 4) every tenant or prospective tenant may dispute
                      inaccurate or incorrect information contained in a tenant
                      screening report directly with the consumer reporting
                      agency.
                    </p>
                    <p className="para-format">
                      <p className="consent-payment-country-tags">
                        New Jersey Applicants:
                      </p>{" "}
                      The Fair Chance in Housing Act requires specific notice to
                      be provided to you, which can be found{" "}
                      <a
                        href="https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:926778a9-9ad7-40e6-9c94-de6e801279ea"
                        target={"_blank"}
                      >
                        here.
                      </a>{" "}
                      By signing this application, you agree that you have
                      received and acknowledge this notice.
                    </p>
                    <p className="para-format">
                      <p className="consent-payment-country-tags">
                        California applicants or residents only:
                      </p>{" "}
                      By signing this application, you also acknowledge receipt
                      of the NOTICE REGARDING BACKGROUND INVESTIGATION PURSUANT
                      TO CALIFORNIA LAW. Please contact the consumer reporting
                      agency if you would like to receive a copy of an
                      investigative consumer report or consumer credit report at
                      no charge if one is obtained by the Company whenever you
                      have a right to receive such a copy under California.
                    </p> */}
                  </div>
                </div>
              </Accordion>
              <div className="mt-4">
                <SupportSection
                  clickHere={clickHere}
                  setClickHere={setClickHere}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="apply-check">
                  <div className="cus-check red-check">
                    {/* <InputCheckBoxField
                      checked={methods.watch("i_accept")}
                      label={`I confirm that I am
                          applying with ${data?.records?.subscriber_name}`}
                      register={register("i_accept")}
                      errors={methods.formState.errors.i_accept}
                    /> */}
                    {/* <input {...register("i_accept")} type="checkbox" id="a1" />
                    <label
                      htmlFor="a1"
                      className="mr-b0 d-flex align-items-center"
                    >
                      <span className="mr-r10"></span>I have read, understood,
                      and consent to Docuverus's Data Policy and Terms of Use.
                    </label>
                    <div className="invalid-feedback">
                      {methods.formState.errors !== undefined &&
                        methods.formState.errors?.i_accept?.message}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="f-a-btn text-right">
                  <button type="submit" className="btn btn-info">
                    Agree & Continue
                  </button>
                  {/* <button className="btn btn-danger" type="submit">
                    Start Application
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </form>
        <ToastContainer />
      </footer>
    </main>
  );
};

export default Instruction;
