import React, { useState, useRef, useEffect } from "react";
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';

import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { CircularProgress, Container, LinearProgress, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useMobileScanSaveMutation,
} from "../../services/identificationApi";
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
export function getOS() {
  const userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

const CameraScan = () => {
  const videoRef = useRef(null);
  const [Save, Result] = useMobileScanSaveMutation();
  const navigate = useNavigate();
  let { id, docId } = useParams();
  const [uploadImag, setUploadimg] = useState('')

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.heic|\.webp|\.bmp|\.svg)$/i;
      if (!allowedExtensions.test(file.name)) {
        alert('File format not supported');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result;
        console.log('Base64:', base64String);
        setUploadimg(base64String);
        if (base64String) {
          Save({
            file: base64String,
            document_id: docId,
            trn_id: id,
            data: {
              OCRData: null,
              raw_data: null,
            },
          }).then((res) => {
            if (res?.data?.status === 200) {
              if (docId !== "2") {
                navigate(`/scan/${id}/success`, { replace: true });
              } else {
                navigate(`/scan/${id}/back/${docId}`, { replace: true });
              }
            } else {
              alert("Fail to save, try again");
              setUploadimg("")
            }
          });
        }
        };
      reader.onerror = (error) => {
        console.error('Error converting file to base64:', error);
        alert("Error converting file, try again");
        setUploadimg("")
      };
    }
  };
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    setTimeout(() => { fileInputRef.current.click(); }, 500)

  }, [])

 

  return (
    <>
      <Box>
        <AppBar sx={{ backgroundColor: '#2dc3e8' }} position="fixed">
          <Toolbar>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Docuverus | Front Side Upload</Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Adjust the height as needed
          }}
        >
          {uploadImag ? <img width={'100%'} src={uploadImag} /> : <>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept={getOS=='MacOS' || getOS()== 'iOS' ? "image/*" : '*'}
              onChange={handleFileChange}
            />
            <Button sx={{ backgroundColor: '#2dc3e8', color: '#ffffff', mt: 2 }} onClick={handleFileUpload} variant="contained"> <IconButton component="span">
              <PhotoCameraOutlinedIcon />
            </IconButton> Snap / Upload document</Button>
            <Typography variant="body2" color="textSecondary" mt={1}>
              Snap a picture or upload from your phone
            </Typography>
            {Result.isError && <Typography variant="body2" color="error" mt={1}>
              {Result.error.status == 401 ? 'The session has expired. Please close the tab and scan the barcode again.' : 'Fail to upload,Pls try again!'}
            </Typography>}

          </>}
          {Result.isLoading && <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <CircularProgress size={24} sx={{ mr: 2 }} />
            <Typography variant="body1">Please wait file Uploading...</Typography>
          </Box>}
        </Box>
      </Box>
    </>
  );
};
export default CameraScan;