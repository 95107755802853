import React from "react";
import { useSelector } from "react-redux";

function SupportSection({ clickHere, setClickHere }) {
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  return (
    <div className="mb-5">
      {useWindowWidth.windowWidth > 992 ? (
        <p className="small">
          Need help completing your application? Docuverus can be reached by
          phone at <strong>856.886.8843</strong> or by email at{" "}
          <strong>support@docuverus.com</strong>
        </p>
      ) : (
        <p className="small">
          Need help completing your application?{" "}
          {clickHere && (
            <span className="click-here" onClick={() => setClickHere(false)}>
              Click here
            </span>
          )}
          {!clickHere && (
            <p>
              Docuverus can be reached by phone at <strong>856.886.8843</strong>{" "}
              or by email at <strong>support@docuverus.com</strong>
            </p>
          )}
        </p>
      )}
      <br/>
      <br/>

    </div>
  );
}

export default SupportSection;
