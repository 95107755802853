import React, { useState } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import InputCheckBoxField from "../../../../registration/Inputs/InputCheckBoxField";
import InputField from "../../../../registration/Inputs/InputField";
import { TiDelete } from "react-icons/ti";
import UploadFile from "../../../../common/uploadFile/uploadFile";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDeleteDocumentMutation } from "../../../../../services/identificationApi";
import { useDispatch, useSelector } from "react-redux";
import { sideNavAction } from "../../../../../store/identification/sideMenuSlice";
import { BASE_URL_API } from "../../../../../config";

const Pets = (props) => {
  const dispatch = useDispatch();
  const { register } = props;
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const { control, setValue, watch, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "have_pet.fields", // unique name for your Field Array
    keyName: "arrId",
    //default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });
  const petfile = useSelector((state) => state.identification.sideMenuSlice?.uploadPetImageId);
  const getPetId = useSelector((state) => state.identification.sideMenuSlice);
  const [open, setOpen] = useState(false);
  const [fieldId, setFieldId] = useState(null);
  const [deleteDocument, result] = useDeleteDocumentMutation();
  const [showPetImage, setShowPetImage] = useState(true);

  const deleteDocumentHandler = (id, type) => {
    deleteDocument(id).then((response) => {
      if (response.data.success === true) {
        dispatch(
          sideNavAction.uploadPetImageId({
            index: fieldId,
            id: id,
            type: type,
          })
        );
        // setShowPetImage(false);
      }
    });
  };

  const hasSSToken = (url) => {
    if (!url) {
      return url;
    } else if (url.includes("?")) {
      return url + "&";
    } else {
      return url;
    }
  };
 
  const token = useSelector(
    (state) => state.persistedReducer.loginSlice.token
  );
  return (
    <>
    
      {fields?.map((field, index) => (
        <div key={field.arrId}>
          <div className="row">
            <div className="col-sm-12 mb-2 ">
              <span className="s-no-index float-left ">{index + 1}</span>{" "}
              <button
                type="button"
                onClick={async () => {
                  remove(index);
                  props?.checkSourceAvailableHandler("pet");
                }}
                title="Delete this Employer"
                className="float-right btn btn-sm btn-danger"
              >
                <TiDelete size={25} /> Delete
              </button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <InputField
                label={`Pet Type`}
                register={register(`have_pet.fields.${index}.pet_type`)}
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4">
              <InputField
                label={`Breed`}
                register={register(`have_pet.fields.${index}.pet_breed`)}
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4">
              <InputField
                type={"tel"}
                notRequired={true}
                label={`Weight`}
                register={register(`have_pet.fields.${index}.pet_weight`)}
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4">
              <InputField
                notRequired={true}
                label={`Pet Name`}
                register={register(`have_pet.fields.${index}.pet_name`)}
              />
            </div>

            <div className="col-sm-6 col-md-4 col-lg-4 mr-t30">
              <small>Has the pet been spayed or neutered?</small>
              <div
                className={`${useWindowWidth.windowWidth < 766
                    ? "d-flex  align-items-center "
                    : "d-flex  align-items-center "
                  }`}
              >
                <label htmlFor="YES">
                  <small>Yes</small>
                  <input
                    className="ml-2"
                    checked={
                      watch(`have_pet.fields.${index}.pet_Spayedneutered`) ==
                      "YES"
                    }
                    type={"radio"}
                    value={"YES"}
                    onChange={(e) =>
                      setValue(
                        `have_pet.fields.${index}.pet_Spayedneutered`,
                        "YES"
                      )
                    }
                  />
                </label>
                <label htmlFor="NO">
                  <small className="ml-2">No</small>
                  <input
                    className="ml-2"
                    checked={
                      watch(`have_pet.fields.${index}.pet_Spayedneutered`) ==
                      "NO"
                    }
                    type={"radio"}
                    value={"NO"}
                    onChange={(e) =>
                      setValue(
                        `have_pet.fields.${index}.pet_Spayedneutered`,
                        "NO"
                      )
                    }
                  />
                </label>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center align-items-center">
              <InputCheckBoxField
                checked={watch(`have_pet.fields.${index}.service_animal`)}
                notRequired={true}
                label={"Check the box if service animal"}
                register={register(`have_pet.fields.${index}.service_animal`)}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9 w-100">
              <div className="doc-up-block">
                <div
                  className={`${useWindowWidth.windowWidth < 766
                      ? "d-flex  d-u-content justify-content-between align-items-center"
                      : "d-u-content d-flex justify-content-between"
                    }`}
                >
                  <div className="position-relative">
                    <h5>
                      Image / Documentation of pet:
                      <br />
                      <small>
                        (You can add a picture and/or documentation about your
                        pet here)
                      </small>
                    </h5>
                    <div className="position-absolute ">

                      {getPetId.uploadPetImageId[`${index}`]?.id && (
                        <>
                         <div className="d-flex mt-4 flex-column align-items-center">
                            <img
                              // src={field?.file_image ? `${field.file_image}` : `${petfile[index]?.file}`}
                              src={`${hasSSToken(field?.file_image ? `${field.file_image}` : `${petfile[index]?.file}`)}?token=${token}`}
                              className="img-thumbnail cursor-pointer"
                              alt=""
                              width={150}
                              height={150}
                              onClick={() =>
                                window
                                  .open(
                                    `${hasSSToken(field?.file ? `${field.file}` : `${petfile[index]?.file}`,)}?token=${token}`,
                                    "_blank"
                                  )
                                  .focus()
                              }
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setFieldId(index);
                                setOpen(true);
                              }}
                              title={`Delete`}
                              className="btn btn-link btn-sm text-danger"
                            >
                              <BiTrash /> Delete
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <UploadFile
                    petIndex={index}
                    label="Upload"
                    className="mr-b10"
                    documentId={14}
                    SourceId={0}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex"></div>
            {errors && errors[index] && errors[index].number}
          </div>
          <br />
          <br />
          <br />
        </div>
      ))}
      {/* fields.length && */}

      <>
        <div className="add-text-btn flex1 text-center ps-2 pt-3 ">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: 0,
                pet_type: "",
                pet_breed: "",
                pet_weight: "",
                pet_name: "",
                pet_Spayedneutered: "YES",
                service_animal: false,
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another Pet or Service Animal
          </button>
        </div>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure want to delete document ?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Warning! Once deleted cannot revert back .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                console.warn(watch(`have_pet.fields.${fieldId}`));
                if (watch(`have_pet.fields.${fieldId}.file_id`)) {
                  console.log("IN IF");
                  deleteDocumentHandler(
                    watch(`have_pet.fields.${fieldId}.file_id`),
                    "DELETE"
                  );
                } else {
                  console.log("IN ELSE");
                  deleteDocumentHandler(
                    getPetId.uploadPetImageId[`${fieldId}`]?.id,
                    "DELETE"
                  );
                }
                setTimeout(() => {
                  setOpen(false);
                }, 200);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default Pets;
