import React, { useState, useEffect } from "react";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { Controller, useController, useFormContext } from "react-hook-form";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const getAddressObject = (address_components) => {
  var ShouldBeComponent = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  var address = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: "",
  };
  address_components.forEach((component) => {
    for (var shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
};

const GoogleAutoComplete = (props) => {
  console.log("Google Places AutoComplete Props", props);
  const { name } = props.register;
  const { control, setValue, watch } = useFormContext();
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const { onChange, ...rest } = field;
  // const {
  //   placesService,
  //   placePredictions,
  //   getPlacePredictions,
  //   isPlacePredictionsLoading,
  // } = usePlacesService({
  //   apiKey: "AIzaSyBwbawNWTS5P3ulxNEtsGeMUD0UdMiNyKk",
  //   options: {
  //     types: ["address"],
  //     componentRestrictions: { country: "us" },
  //   },
  // });

  //const value = watch(name);
  // useEffect(() => {
  //   getPlacePredictions({ input: value });
  // }, [value]);
 // const uid = Math.round(new Date().getTime() / 1000);

 function startsWithSpace(str) {
  return str.startsWith(' ');
}

  return (
    <>
      <label
        htmlFor={name}
        className={`form-label ${error !== undefined && "text-danger"}`}
      >
        {props.label} {required && <span className="red">*</span>}
      </label>
      <Autocomplete
        apiKey={"AIzaSyBwbawNWTS5P3ulxNEtsGeMUD0UdMiNyKk"}
        className={`form-control cus-input-control`}
        onPlaceSelected={(place, inputRef, autocomplete) => {
          onChange(place);
          const address = getAddressObject(
            place.address_components
          );

          

          console.log("address.street",address)

          console.log(startsWithSpace(`${address.home.trim()} ${address.street.replace(/^ +/, '')}`));
          let fullAddress = '';
          if (address.home.trim() !== '') {
          fullAddress = `${address.home.trim()} ${address.street.trim()}`;
          } else {
             fullAddress = `${address.street.trim()}`;
          }

           setValue(props.groupFieldName.street, fullAddress, {
            shouldValidate: true,
          });

          // setValue(props.groupFieldName.street, `${address.home.trim()} ${address.street.replace(/^ +/, '')}`, {
          //   shouldValidate: true,
          // });
          setValue(props.groupFieldName.city, address.city, {
            shouldValidate: true,
          });
          if (props.groupFieldName.home)
            setValue(props.groupFieldName.home, address.home, {
              shouldValidate: true,
            });
          if (props.groupFieldName.zip)
            setValue(props.groupFieldName.zip, address.postal_code, {
              shouldValidate: true,
            });
          const getSelected = props.groupFieldName?.state_data?.filter(
            (item) => {
              return item.label.includes(address.region);
            }
          );
          if (getSelected?.length) {
            setValue(props.groupFieldName.state, getSelected[0], {
              shouldValidate: true,
            });
          }
        }
        }
        placeholder={props.label}
        options={{
          types: ["address"],
          componentRestrictions: { country: "us" }
        }}
      />
      {/* <input
        placeholder={props.placeholder || `${props.label}`}
        id={name}
        type="text"
        list={`place${uid}`}
        disabled={props.disable === true ?? "disabled"}
        className={`form-control cus-input-control ${
          required && !field.value && "is-warning"
        } ${error !== undefined && "is-invalid"}`}
        onChange={(e) => {
          onChange(e);
          const selected = placePredictions.find((item, index) => {
            if (item.description === e.target.value) {
              return true;
            }
          });
          if (selected) {
            placesService.getDetails(
              { placeId: selected.place_id },
              (placeDetails) => {
                const address = getAddressObject(
                  placeDetails.address_components
                );
                console.log("Check Address Line:115", address);
                setValue(name, `${address.home} ${address.street}`, {
                  shouldValidate: true,
                });
                setValue(props.groupFieldName.city, address.city, {
                  shouldValidate: true,
                });
                if (props.groupFieldName.home)
                  setValue(props.groupFieldName.home, address.home, {
                    shouldValidate: true,
                  });
                if (props.groupFieldName.zip)
                  setValue(props.groupFieldName.zip, address.postal_code, {
                    shouldValidate: true,
                  });
                const getSelected = props.groupFieldName?.state_data?.filter(
                  (item) => {
                    return item.label.includes(address.region);
                  }
                );
                if (getSelected?.length) {
                  setValue(props.groupFieldName.state, getSelected[0], {
                    shouldValidate: true,
                  });
                }
                console.log(
                  "🚀 ~ file: googleAutocomplete.js ~ line 116 ~ placesService.getDetails ~ address",
                  address
                );
                console.log(
                  "🚀 ~ file: googleAutocomplete.js ~ line 115 ~ placesService.getDetails ~ placeDetails",
                  placeDetails
                );
              }
            );
          }
        }}
        {...rest}
      />
      <datalist id={`place${uid}`}>
        {!isPlacePredictionsLoading &&
          placePredictions.map((item, key) => (
            <option key={key} value={item.description} />
          ))}
      </datalist> */}
      <div className="invalid-feedback">
        {error !== undefined && error.message}
      </div>
    </>
  );
};

export default GoogleAutoComplete;
