import React, { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import "./index.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";

const InputFieldPhone = (props) => {
  const { name } = props.register;
  const { control, setValue, getValues } = useFormContext();

  const required = props.notRequired === undefined ? true : !props.notRequired;
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const error = fieldState.error !== undefined;
          return (
            <>
              <label
                htmlFor={name}
                className={`form-label ${error && "text-danger"} `}
              >
                {props.label} {required && <span className="red">*</span>}
              </label>
              <PhoneInput
                disableDropdown={false}
                country={"us"}
                id={name}
                name={name}
                {...field}
                countryCodeEditable={false}
                inputProps={{
                  ref: field.ref,
                }}
                onChange={(value, country, e, formattedValue) => {
                  field.onChange(formattedValue);
                }}
                onBlur={(e, country) => {
                  field.onBlur(e,country);
                }}
                inputClass={`${required && !field.value && "is-warning"} ${
                  error && "is-invalid"
                }`}
                disabled={viewOnlyFlag || props.disable}
              />
              {!error && <br />}
              <div className="invalid-feedback">
                {error && fieldState.error.message}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default InputFieldPhone;
