import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import InputField from "../../../../registration/Inputs/InputField";
import SelectField from "../../../../registration/Inputs/SelectField";
import { TiDelete } from "react-icons/ti";
import InputFieldPhone from "../../../../registration/Inputs/InputFieldPhone";

const EmergencyContact = (props) => {
  console.log("Ememgency contact props", props);
  const { register } = props;
  const { control, watch, setValue, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "emergency_reference.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <>
      <div className="blue-box pd15 mr-b30">
        {fields.map((field, index) => {
          console.log("Check FIELDS VALUE", field);
          if (
            watch(`emergency_reference.fields.${index}.reference_copy`)
              ?.value >= 0
          ) {
            if (
              !getValues(
                `emergency_reference.fields.${index}.reference_copy_status`
              )
            ) {
              /**
               *
               */
              setValue(
                `emergency_reference.fields.${index}`,
                getValues(
                  `reference.fields.${
                    getValues(
                      `emergency_reference.fields.${index}.reference_copy`
                    )?.value
                  }`
                ),
                { shouldValidate: true }
              );
              setValue(
                `emergency_reference.fields.${index}.reference_copy_status`,
                true
              );
            }
          }
          return (
            <div key={field.arrId} style={{ marginTop: "20px" }}>
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <span className="s-no-index float-left">{index + 1}</span>{" "}
                  <button
                    type="button"
                    onClick={async () => {
                      remove(index);
                      props?.checkSourceAvailableHandler("emergency");
                    }}
                    title="Delete this Employer"
                    className="float-right btn btn-sm btn-danger"
                  >
                    <TiDelete size={25} /> Delete
                  </button>
                </div>
              </div>

              <div className="row">
                {props.referenceList.filter(
                  (item) =>
                    !!item.first_name && !!item.last_name && !!item.email
                ).length > 0 && (
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        {watch(
                          `emergency_reference.fields.${index}.first_name`
                        ) === "" && (
                          <SelectField
                            notRequired={true}
                            defaultValue={
                              watch(
                                `emergency_reference.fields.${index}.first_name`
                              )
                                ? watch(
                                    `emergency_reference.fields.${index}.first_name`
                                  ) +
                                  " " +
                                  watch(
                                    `emergency_reference.fields.${index}.last_name`
                                  )
                                : ""
                            }
                            disable={watch(
                              `emergency_reference.fields.${index}.reference_copy_status`
                            )}
                            options={props.referenceList
                              .filter(
                                (item) =>
                                  !!item.first_name &&
                                  !!item.last_name &&
                                  !!item.email
                              )
                              .map((item, index) => {
                                const name =
                                  item.first_name + " " + item.last_name ||
                                  index;
                                return {
                                  value: index,
                                  label: name,
                                };
                              })}
                            label="Copy data from reference"
                            register={register(
                              `emergency_reference.fields.${index}.reference_copy`
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <InputField
                    label={`First Name`}
                    register={register(
                      `emergency_reference.fields.${index}.first_name`
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4">
                  <InputField
                    label={`Last Name`}
                    register={register(
                      `emergency_reference.fields.${index}.last_name`
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4">
                  <InputField
                    label={`Email`}
                    register={register(
                      `emergency_reference.fields.${index}.email`
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4">
                  <InputFieldPhone
                    label={`Mobile Number`}
                    register={register(
                      `emergency_reference.fields.${index}.mobile`
                    )}
                    notRequired={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  label={`Relationship to Applicant`}
                  register={register(`emergency_reference.fields.${index}.relationship`)}
                />
              </div>
                {errors && errors[index] && errors[index].number}
              </div>
            </div>
          );
        })}
        <div className="add-text-btn flex1 text-center ps-2 pt-3">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another emergency contact
          </button>
        </div>
      </div>
    </>
  );
};

export default EmergencyContact;
