import { ErrorMessage } from "@hookform/error-message";
import { Alert, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import InputFieldPhone from "./InputFieldPhone";
import SelectField from "./SelectField";

const InputFieldArray = (props) => {
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const disabled=props.disabled||false;
  const { register } = props;
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    focusAppend: true,
    name: "mobile", // unique name for your Field Array

    // keyName: "id", default to "id", you can change the key name
  });
  const { errors, dirtyFields } = useFormState({
    control,
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className="d-flex align-items-center">
            <div className="mo-i-box">
              {props.showNumberType && (
                <SelectField
                  notRequired={true}
                  options={[
                    {
                      value: "Home",
                      label: "Home",
                    },
                    {
                      value: "Work",
                      label: "Work",
                    },
                    {
                      value: "Mobile",
                      label: "Mobile",
                    },
                  ]}
                  label={`Phone Type`}
                  register={register(`other.${index}.type`)}
                />
              )}
              <InputFieldPhone
                disabled={disabled}
                label={
                  !props?.showNumberType ? `Mobile Number` : `Phone Number`
                }
                notRequired={props?.notRequired}
                register={
                  props?.rigesterKey == "mobile"
                    ? register(`${props?.rigesterKey}.${index}.number`)
                    : register(`${props?.rigesterKey}.${index}.number`)
                }
              />
              {errors && errors[index] && errors[index].number}
            </div>
            {props?.addMultipleNumber && (
              <div className="d-flex justify-content-evenly">
                <div className="align-self-start ">
                  {index !== 0 && (
                    <button
                      className="add-num-icon"
                      type="button"
                      onClick={(event) => remove(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        fill="currentColor"
                        className="bi bi-x-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                      </svg>{" "}
                      &nbsp; &nbsp;
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-center align-items-center ">
                  {index === fields.length - 1 && index <= 1 && (
                    <div className=" ms-2">
                      <button
                        className="add-num-number"
                        style={{ paddingLeft: "20px" }}
                        type="button"
                        onClick={(event) => append({ number: "" })}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                            fill="#2DC3E8"
                          />
                        </svg>
                        &nbsp;
                        <span
                          className={`${
                            useWindowWidth.windowWidth < 766 ? "font-10" : ""
                          }`}
                        >
                          Add another number
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
      <div className="row">
        <div className="col-12">
          <Stack
            sx={{ width: "100%" }}
            style={{ textTransform: "capitalize", marginTop: "10px" }}
            spacing={2}
          >
            <ErrorMessage
              errors={errors}
              name="mobile"
              render={({ message }) => {
                if (message) return <Alert severity="error">{message}</Alert>;

                return <></>;
              }}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default InputFieldArray;
