import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, TextField } from '@mui/material';
import CustomUpload from '../../components/mlFileUpload/MLFileUpload';
import Animated from "react-mount-animation";

const PredictBankStatement = (props) => {
    const [childData, setChildData] = React.useState();
    const [childPdfFile, setchildPdfFile] = React.useState();
    const handleCallbackFileUploadResult = (childData) => {
        setChildData(childData);
    }
    const handleCallbackFileUpload = (childData) => {
        setchildPdfFile(childData);
    }
    return (

        <>
            <Container>
                <Box sx={{ height: '100vh', flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h4>Predict your Bank Statement here</h4>
                        </Grid>
                        <Grid item xs={5}>
                            <CustomUpload onlyPdf={true} documentId="6" handleCallbackFileUpload={handleCallbackFileUpload} handleCallbackFileUploadResult={handleCallbackFileUploadResult} />
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        
                        {childPdfFile&&(
                        <>
                        <Grid item xs={8}>
                            <h5>Statement Preview</h5>
                            <object data={childPdfFile&&URL.createObjectURL(childPdfFile)} type="application/pdf" width="100%" height="450px">
                            </object>
                        </Grid>
                        <Grid item xs={4}>
                            <h5>Statement Name</h5>
                            <Box
                                sx={{
                                    width: '100wh',
                                    height: 40,
                                    backgroundColor: '#F0F0F0',
                                    padding: '8px'
                                }}
                            >
                                <p>{(childData?.data?.records?.class_predicted)}</p>
                            </Box>
                        </Grid>
                        </>)}
                    </Grid>
                </Box>
            </Container>

        </>
    );
};
export default PredictBankStatement;