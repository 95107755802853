import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected: "identification",
  applicantFirstName: "",
  applicantMiddleName: "",
  applicantLastName: "",
  applicantNameMatched: null,
  applicantDOBMatched: null,
  applicationStatus: {},
  firstnameError: false,
  lastnameError: false,
  emailError: false,
  mobilenumberError: false,
  dateofbirthError: false,
  aliasfirstnameError: false,
  aliaslastnameError: false,
  isaliasrequired: null,
  isproofofidentityrequired: null,
  proofOfIdentityFilePresent: null,
  personalDetailsStatus: false,
  proofOfIdentityStatus: false,
  addressStatus: false,
  ssnStatus: false,
  applicantDetailsStatus: false,
  contactsStatus: false,
  financialDetailsStatus: false,
  otherStatus: false,
  employeeIncomeStatus: false,
  otherIncomeStatus: false,
  bankingInformationStatus: false,
  otherDocumentStatus: false,
  termsAndConditionStatus: false,
  paymentSignatureStatus: false,
  // uploadPetImageId: null,
  uploadPetImageId: [],
  windowWidth: window.innerWidth,
  scanMenu: 2,
  applicationFee: 0,
  subscriberFee: 0,
};
const sideNav = createSlice({
  name: "side_menu_nav",
  initialState,
  reducers: {
    sideNav: (state, action) => {
      state.selected = action.payload;
    },
    applicationStatusState: (state, action) => {
      state.applicationStatus = action.payload;
    },
    personalDetailStatus: (state, action) => {
      console.log("Check State and Action", state, action);
      state.personalDetailsStatus = action.payload;
    },
    proofOfIdentityStatus: (state, action) => {
      state.proofOfIdentityStatus = action.payload;
    },
    applicantNameMatched: (state, action) => {
      state.applicantNameMatched = action.payload;
    },
    applicantDOBMatched: (state, action) => {
      state.applicantDOBMatched = action.payload;
    },
    // uploadPetImageId: (state, action) => {
    //   state.uploadPetImageId = action.payload;
    // },
    uploadPetImageId: (state, action) => {
      console.log(
        "Check State and Action for UploadPetImage:66",
        state,
        action
      );
      if (action.payload?.type == "APICALL") {
        state.uploadPetImageId = action.payload?.data?.map((data, index) => {
          return {
            index: index,
            id: data?.file_id,
          };
        });
      }
      if (action.payload?.type === "UPLOAD") {
        if (state.uploadPetImageId[action.payload?.index]) {
          state.uploadPetImageId[action.payload?.index] = {
            index: action.payload?.index,
            id: action.payload?.id,
            file: action.payload?.file,
          };
          return;
        }
        state.uploadPetImageId = [
          ...state.uploadPetImageId,
          {
            index: action.payload?.index,
            id: action.payload?.id,
            file: action.payload?.file,
          },
        ];
      }
      if (action.payload?.type === "DELETE") {
        for (let index = 0; index < state.uploadPetImageId.length; index++) {
          const element = state.uploadPetImageId[index];

          if (element?.index == action.payload?.index) {
            state.uploadPetImageId[element.index] = {
              index: action.payload?.index,
              id: null,
            };
          }
        }
      }
      if (action.payload?.type === "DELETESOURCE") {
        state.uploadPetImageId = [];
      }
      // state.uploadPetImageId.index = action.payload.index;
      // state.uploadPetImageId.id = action.payload.id;
      // if (action.payload?.type === "LOCAL") {
      //   // state.uploadPetImageId = state.uploadPetImageId.filter(
      //   //   (pets, index) => {
      //   //     return pets?.id !== action.payload?.id;
      //   //   }
      //   // );
      //   for (let index = 0; index < state.uploadPetImageId.length; index++) {
      //     const element = state.uploadPetImageId[index];
      //     console.log("PREV0->", element);

      //     if (element?.index == action.payload?.index) {
      //       console.log("PREV->", element);
      //       console.log("NEXT->", action.payload);

      //       state.uploadPetImageId[element.index] = {
      //         index: action.payload?.index,
      //         id: null,
      //       };
      //       // state.uploadPetImageId = [
      //       //   ...state.uploadPetImageId,
      //       //   {
      //       //     index: action.payload?.index,
      //       //     id: null,
      //       //   },
      //       // ];
      //     }
      //   }
      // } else {
      //   console.log("In Else Part for delete");
      //   state.uploadPetImageId = [];
      //   // state.uploadPetImageId = [
      //   //   ...state.uploadPetImageId,
      //   //   {
      //   //     index: action.payload?.index,
      //   //     id: action.payload?.id,
      //   //   },
      //   // ];
      // }
      // state.uploadPetImageId = [
      //   ...state.uploadPetImageId,
      //   {
      //     index: action.payload?.index,
      //     id: action.payload?.id,
      //   },
      // ];
      // state.uploadPetImageId = state.uploadPetImageId.filter((petDetails, index) => {
      //   petDetails.id == action.payload?.id ? "" : ""
      // })
    },
    firstnameError: (state, action) => {
      state.firstnameError = action.payload;
    },
    lastnameError: (state, action) => {
      state.lastnameError = action.payload;
    },
    emailError: (state, action) => {
      state.emailError = action.payload;
    },
    mobilenumberError: (state, action) => {
      state.mobilenumberError = action.payload;
    },
    dateofbirthError: (state, action) => {
      state.dateofbirthError = action.payload;
    },
    windowWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    aliasfirstnameError: (state, action) => {
      state.aliasfirstnameError = action.payload;
    },
    aliaslastnameError: (state, action) => {
      state.aliaslastnameError = action.payload;
    },
    isaliasrequired: (state, action) => {
      state.isaliasrequired = action.payload;
    },
    isproofofidentityrequired: (state, action) => {
      state.isproofofidentityrequired = action.payload;
    },
    proofOfIdentityFilePresent: (state, action) => {
      state.proofOfIdentityFilePresent = action.payload;
    },
    scanMenuSelect: (state, action) => {
      state.scanMenu = action.payload;
    },
    enterApplicationFee: (state, action) => {
      state.applicationFee = action.payload;
    },
    enterSubscriberFee: (state, action) => {
      state.subscriberFee = action.payload;
    },
  },
});

export const sideNavAction = sideNav.actions;

export default sideNav.reducer;
