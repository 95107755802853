import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomUpload from "../CustomUpload/CustomUpload";
import DropboxApi from "../dropboxApi/dropboxApi";
import GoogleDriveApi from "../googleDriveApi/googleDriveApi";

const UploadFile = (props) => {
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  if (viewOnlyFlag) return <></>;
  return (
    <div>
      <CustomUpload
        allowOfferLetter={
          props?.allowOfferLetter ? props?.allowOfferLetter : null
        }
        onlyPdf={props.onlyPdf || false}
        petIndex={props?.petIndex}
        register={props?.register ? props.register : ""}
        disable={props?.disable}
        verifyDetails={props?.verifyDetails}
        label={props.label}
        SourceId={props.SourceId}
        className={props.className}
        documentId={props.documentId}
        multiple={props?.multiple}
      />
      {/* 
            <DropboxApi documentId={props.documentId} className={props.className}/>

            <GoogleDriveApi  documentId={props.documentId} className={props.className}/> */}
    </div>
  );
};

export default UploadFile;
