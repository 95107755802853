import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import InputField from "../../../../registration/Inputs/InputField";
import { TiDelete } from "react-icons/ti";
import InputFieldPhone from "../../../../registration/Inputs/InputFieldPhone";

const Reference = (props) => {
  const { register } = props;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "reference.fields", // unique name for your Field Array
    keyName: "reference_id", //default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.reference_id}>
          <div className="blue-box pd15">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <span className="s-no-index float-left">{index + 1}</span>{" "}
                <button
                  type="button"
                  onClick={async () => {
                    remove(index);
                    props?.checkSourceAvailableHandler("reference");
                  }}
                  title="Delete this Employer"
                  className="float-right btn btn-sm btn-danger"
                >
                  <TiDelete size={25} /> Delete
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  label={`First Name`}
                  register={register(`reference.fields.${index}.first_name`)}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  label={`Last Name`}
                  register={register(`reference.fields.${index}.last_name`)}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  label={`Email`}
                  register={register(`reference.fields.${index}.email`)}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputFieldPhone
                  label={`Mobile Number`}
                  register={register(`reference.fields.${index}.mobile`)}
                  notRequired={true}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  label={`Relationship to Applicant`}
                  register={register(`reference.fields.${index}.relationship`)}
                />
              </div>
              {errors && errors[index] && errors[index].number}
            </div>
          </div>
        </div>
      ))}
      <div className="blue-box pd15 mr-b30">
        <div className="add-text-btn flex1 text-center ps-2 pt-3">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                mobile: null,
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another reference
          </button>
        </div>
      </div>
    </>
  );
};

export default Reference;
