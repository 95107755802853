import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowCircleRightIcon from "@mui/icons-material/DoubleArrow";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetIdDocumentStatusQuery } from "../../services/identificationApi";
import { useMobileScanSaveMutation } from "../../services/identificationApi";

const ScanMenu = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { data, isLoading, refetch } = useGetIdDocumentStatusQuery(id);
  const [Save, Result] = useMobileScanSaveMutation();
  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar sx={{ backgroundColor: '#2dc3e8' }} position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Docuverus | Document Type
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Container maxWidth="sm">
        <Paper sx={{ marginTop: "40px" }}>
          <MenuList>
            <MenuItem
              onClick={() => {
                if (data?.records?.back?.res) {
                  alert("You have aready uploaded documents");
                  return;
                } else if (
                  data?.records?.front?.res &&
                  data?.records?.back?.res
                ) {
                  alert("You have aready uploaded documents");
                  return;
                } else {
                  navigate(`/scan/${id}/camera/2`);
                }
              }}
            >
              <ListItemIcon>
                <ArrowCircleRightIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Driver's License </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                if (data?.records?.back?.res) {
                  alert("You have aready uploaded documents");
                  return;
                }
                if (data?.records?.front?.res && data?.records?.back?.res) {
                  alert("You have aready uploaded documents");
                  return;
                }
                navigate(`/scan/${id}/camera/1`);
              }}
            >
              <ListItemIcon>
                <ArrowCircleRightIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Passport</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                if (data?.records?.back?.res) {
                  alert("You have aready uploaded documents");
                  return;
                }
                if (data?.records?.front?.res && data?.records?.back?.res) {
                  alert("You have aready uploaded documents");
                  return;
                }
                navigate(`/scan/${id}/camera/10`);
              }}
            >
              <ListItemIcon>
                <ArrowCircleRightIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>PRID</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                if (data?.records?.back?.res) {
                  alert("You have aready uploaded documents");
                  return;
                }
                if (data?.records?.front?.res && data?.records?.back?.res) {
                  alert("You have aready uploaded documents");
                  return;
                }
                navigate(`/scan/${id}/camera/11`);
              }}
            >
              <ListItemIcon>
                <ArrowCircleRightIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Visa</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
        <Typography variant="p">
          Select the types of document you would to upload*
        </Typography>
      </Container>
    </>
  );
};

export default ScanMenu;