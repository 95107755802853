import React from "react";
//import $ from "jquery";

import {
  useGetIdentificationDataQuery,
  useGetDetailsQuery,
  useAddToReviewQMutation,
  useGetApplicantStatusQuery,

} from "../../services/identificationApi";

import Loader from "react-loader-spinner";
import { useGetMasterRegistrationDataQuery } from "../../services/registrationApi";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Identification_doc_upload from "./Identification_doc_upload";
import { toast, ToastContainer } from "react-toastify";

const View = (props) => {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  let { data, isFetching, isLoading1, isError, isSuccess, refetch } =
    useGetApplicantStatusQuery(trn_id);

  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  let identificationData = useGetIdentificationDataQuery(trn_id);
 
  const { isLoading } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });
  // const [addToReviewQ, result] = useAddToReviewQMutation();

  // const addToReviewQfun = async () => {
  //   const payload = {
  //     trn_id,
  //     role: "applicant",
  //     status: data?.records?.application_status_id,
  //   }
  //   const response = await toast.promise(addToReviewQ(payload), {
  //     pending: "Saving",
  //     error: "Saving failed 🤯",
  //   });
  //   if (response) {
  //     return "Done!!"
  //   }

  // }


  let contextualData = useGetDetailsQuery(trn_id);


  if (isLoading && identificationData.isLoading && contextualData.isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  else
    return (
      <main className="body-wrap white-box">
        <section className="application-body-wrap">
          <div className="container-md white-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    <Identification_doc_upload />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={() => {
                  if (props?.setOpen) {
                    props.setOpen(false);
                    // addToReviewQfun();
                  }
                }}
                className="btn btn-info mt-3 mb-2 text-light"
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      </main>
    );
};

export default View;
