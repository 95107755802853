import React from "react";
import { useSelector } from "react-redux";
import UploadFile from "../../components/common/uploadFile/uploadFile";
import {
  useGetIncomeBankingQuery,
  useGetSectionStatusQuery,
} from "../../services/identificationApi";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { RiInformationLine } from "react-icons/ri";

import FileIcon from "../../components/common/FileIcon";
import Paystub_Source from "./Paystub_Source";

const ProofOfDoc_doc_upload = () => {
  const [taxOtherDocs, setTaxOtherDocs] = React.useState(false);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { isLoading, isSuccess, isError, data } =
    useGetIncomeBankingQuery(trn_id);
  return (
    <div className="ei-wrap white-box">
      <div className="ei-body">
        <div className="row">
          <div className="col">
            <h6>Other Documents</h6>
          </div>
        </div>
        <div className="row  blue-box">
          <div className="col-sm-12">
            <div className="note-block text-center mr-b30">
              <h5 className="justify-content-center">
                <span>Note:</span>
                If you want to upload any other document, you can do it here
              </h5>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="d-flex justify-content-between align-items-center mr-t20">
              <div className="ssn-text-block">
                <h4>
                  Tax Documents OR Other Documents:
                  <RiInformationLine
                    className="ms-2 cursor-pointer"
                    fill="#D3AF51"
                    size={20}
                    onClick={() => setTaxOtherDocs(true)}
                  />
                </h4>
                <h5>(W2, 1099, tax return,etc)</h5>
                <small className="highlight-text-background">
                  Click the (i) button above for details
                </small>
              </div>
              <div className="ssn-btn">
                <UploadFile
                  label="Upload Back"
                  className="mr-b10"
                  documentId={5}
                  SourceId={1}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            {
              <div className="row mt-1">
                {data?.other_documents?.map((item, index) => {
                  return (
                    <div
                      key={item.application_document_id}
                      className="col-sm-2 text-center"
                    >
                      <FileIcon fileObj={item} />
                    </div>
                  );
                })}
              </div>
            }
          </div>
        </div>
        <Dialog
          open={taxOtherDocs}
          onClose={() => {
            setTaxOtherDocs(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Only upload clear, complete, straight documents and that original
              PDF's downloaded from an HR or payroll website work best.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                setTaxOtherDocs(false);
              }}
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ProofOfDoc_doc_upload;
