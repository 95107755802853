import React, { useState, useEffect, useCallback } from "react";
import UploadFile from "../../../common/uploadFile/uploadFile";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import { useFormContext } from "react-hook-form";
import { AccordionDetails, Alert, Stack } from "@mui/material";
import {
  useDeleteDocumentMutation,
  useGetDetailsQuery,
  useGetDocumentImgQuery,
} from "../../../../services/identificationApi";
import { BiTrash } from "react-icons/bi";
import moment from "moment";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sideNavAction } from "../../../../store/identification/sideMenuSlice";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import QRCode from "react-qr-code";
import { BASE_URL_LOCAL, protocol } from "../../../../config";
import InputField from "../../../registration/Inputs/InputField";
import { ErrorMessage } from "@hookform/error-message";
import RadioInputField from "../../../registration/Inputs/RadioInputField";
import "./index.css";
const FilePreview = (props) => {
  const { data, isLoading } = useGetDocumentImgQuery(props.id);

  return (
    <>
      <img src={`data:image/jpeg;base64,${data}`} />
      {/* <img
      src={`${getValues(
        `identification_data.file_image`
      )}`}
      className="img-thumbnail cursor-pointer"
      alt=""
      width={150}
      height={150}
      onClick={() =>
        window
          .open(
            `${watch(
              "identification_data.file"
            )}`,
            "_blank"
          )
          .focus()
      }
    /> */}
    </>
  );
};
const ProofOfIdentity = (props) => {
  const navigate = useNavigate();
  const [selectOtherRadioButton, setSelectOtherRadioButton] =
    React.useState(false);

  const dispatch = useDispatch();
  const nameMatched = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  console.log(token);
  const [selectedPOI, setSelectedPOI] = useState("Passport");
  const { register, watch, getValues, setValue, formState, trigger } =
    useFormContext();
  const [deleteDocument, result] = useDeleteDocumentMutation();

  const getIdentificationData = useGetDetailsQuery(trn_id);

  const [open, setOpen] = useState(false);
  const [deleteDocumentType, setDeleteDocumentType] = useState("");

  const deleteDocumentHandler = (id) => {
    deleteDocument(id).then((response) => {
      if (response.data.success === true) {
        dispatch(sideNavAction.applicantNameMatched(null));
        dispatch(sideNavAction.applicantDOBMatched(null));
      }
    });
  };

  const verifyDetails = async () => {
    let Barcodedata = await watch("identification_data.Barcodedata");

    if (Barcodedata?.length > 0) {
      console.log("In IFF FOR BARCODE");
      let DOB = Barcodedata[8]?.field_value_original;
      if (
        Barcodedata[1]?.field_value_original == "" ||
        Barcodedata[3]?.field_value_original == "" ||
        DOB == ""
      ) {
        return;
      }
      if (
        Barcodedata[1]?.field_value_original?.toLowerCase() !==
          getValues("identification_data.first_name")?.toLowerCase() ||
        Barcodedata[3]?.field_value_original?.toLowerCase() !==
          getValues("identification_data.last_name")?.toLowerCase()
      ) {
        dispatch(sideNavAction.applicantNameMatched(false));
      }
      if (
        Barcodedata[1]?.field_value_original?.toLowerCase() ==
          getValues("identification_data.first_name")?.toLowerCase() ||
        Barcodedata[3]?.field_value_original?.toLowerCase() ==
          getValues("identification_data.last_name")?.toLowerCase()
      ) {
        dispatch(sideNavAction.applicantNameMatched(true));
      }

      //-----------------------------------------------------
      // DOB - Matched / Unmatched

      if (
        DOB !==
        moment(getValues("identification_data.DOB")).format("MM/DD/YYYY")
      ) {
        dispatch(sideNavAction.applicantDOBMatched(false));
      }
      if (
        DOB == moment(getValues("identification_data.DOB")).format("MM/DD/YYYY")
      ) {
        dispatch(sideNavAction.applicantDOBMatched(true));
      }
    }
  };
  const backSide = watch("identification_data.backfile");

  let firstNameChange = watch("identification_data.first_name");
  let lastNameChange = watch("identification_data.last_name");
  let dOBDateChange = watch("identification_data.DOB");

  useEffect(() => {
    if (backSide) {
      verifyDetails();
    }
  }, [backSide]);

  useEffect(() => {
    verifyDetails();
  }, [firstNameChange, lastNameChange, dOBDateChange]);

  useEffect(() => {
    const subscribe = watch((value, { name, type }) => {
      trigger("identification_data.file");
      trigger("identification_data.backfile");
      console.log("POI 133:", value, name, type);
      if (name === "identification_data.file" && type === "change") {
        if (watch("identification_data.file") !== null) {
          dispatch(sideNavAction.proofOfIdentityFilePresent("Present"));
        }
        if (watch("identification_data.file") === null) {
          dispatch(sideNavAction.proofOfIdentityFilePresent("notPresent"));
        }
      }
    });
    return subscribe.unsubscribe();
  }, [watch, dispatch, setValue, getValues]);

  const [nextSection, setNextSection] = useState(false);
  const hasSSToken = (isImg=true) => {
    let url = getValues(`identification_data.file_image`);
    if(!isImg)
    {
      url=getValues("identification_data.file");
    }
    if (!url) {
      return url;
    } else if (url.includes("?")) {
      return url + "&";
    } else {
      return url;
    }
    console.log(url);
    return url;
  };

  const hasSSTokenBack = (isImg=true) => {
    let url = getValues(`identification_data.backfile_image`);
    if(!isImg)
    {
      url=getValues("identification_data.backfile");
    }
    if (!url) {
      return url;
    } else if (url.includes("?")) {
      return url + "&";
    } else {
      return url;
    }
    console.log(url);
    return url;
  };

  const debouncedSave = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          setNextSection(await props.checkValidation("ProofOfIdentity", false));
        })(),
      1000
    ),
    [] // will be created only once initially
  );

  const btnStatus = watch("identification_data.availableToUpload_id");
  if (btnStatus) {
    debouncedSave("sdfds");
  }

  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  return (
    <AccordionDetails>
      <div className={`${useWindowWidth.windowWidth < 766 ? " mt-2" : ""}`}>
        <CustomRadioButton
          disable={
            !!watch("identification_data.file") ||
            !!watch("identification_data.backfile")
          }
          register={register("identification_data.availableToUpload_id")}
          label="Do you have a proof of identity available?"
        />
      </div>

      {watch("identification_data.availableToUpload_id") == "Yes" && (
        <>
          <small className="highlight-text-background">
            Please upload CLEAR images of your ID here. Click the “i” for
            details.
          </small>
          <div className="licence-b-card mr-t20">
            <div className="row">
              <div
                className={`d-flex align-items-center pt-5 pb-5 ${
                  useWindowWidth.windowWidth < 766 ? "" : "col-md-6 "
                }`}
              >
                <ul>
                  <li>
                    <div className="r-list-radio mb-2 d-flex align-items-center">
                      <label htmlFor="l1" className="cus-r-btn d-flex mr-b0">
                        <input
                          disabled={
                            watch("identification_data.file") !== null ||
                            watch("identification_data.backfile") !== null
                              ? true
                              : false
                          }
                          type="radio"
                          id="l1"
                          checked={
                            watch("identification_data.documenttype_id") == 2 ||
                            watch("identification_data.documenttype_id") == 13
                          }
                          onChange={(e) => {
                            setValue("identification_data.documenttype_id", 2);
                          }}
                          name="identity-doc"
                        />
                        <span className="mr-r6"></span>
                        <div className="d-flex flex-column">
                          <div className="d-flex">Drivers license/State ID</div>
                        </div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="r-list-radio mb-2 d-flex align-items-center">
                      <label
                        htmlFor="passport"
                        className="cus-r-btn d-flex mr-b0"
                      >
                        <input
                          type="radio"
                          disabled={
                            watch("identification_data.file") !== null ||
                            watch("identification_data.backfile") !== null
                              ? true
                              : false
                          }
                          id="passport"
                          checked={
                            watch("identification_data.documenttype_id") == 1
                          }
                          onChange={(e) => {
                            setValue("identification_data.documenttype_id", 1);
                          }}
                          name="identity-doc"
                        />
                        <span className="mr-r6"></span>
                        US Passport
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="r-list-radio mb-2 d-flex align-items-center">
                      <label
                        htmlFor="permanant-r"
                        className="cus-r-btn d-flex mr-b0"
                      >
                        <input
                          disabled={
                            watch("identification_data.file") !== null ||
                            watch("identification_data.backfile") !== null
                              ? true
                              : false
                          }
                          type="radio"
                          id="permanant-r"
                          checked={
                            watch("identification_data.documenttype_id") == 10
                          }
                          onChange={(e) => {
                            setValue("identification_data.documenttype_id", 10);
                          }}
                          name="identity-doc"
                        />
                        <span className="mr-r6"></span>
                        Permanent Residence ID
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="r-list-radio mb-2 d-flex align-items-center">
                      <label htmlFor="visa" className="cus-r-btn d-flex mr-b0">
                        <input
                          disabled={
                            watch("identification_data.file") !== null ||
                            watch("identification_data.backfile") !== null
                              ? true
                              : false
                          }
                          type="radio"
                          id="visa"
                          checked={
                            watch("identification_data.documenttype_id") == 11
                          }
                          onChange={(e) => {
                            setValue("identification_data.documenttype_id", 11);
                          }}
                          name="identity-doc"
                        />
                        <span className="mr-r6"></span>
                        Visa
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              {!viewOnlyFlag && (
                <div className="mb-4">
                  {useWindowWidth.windowWidth < 776 && (
                    <div className="row ">
                      <button
                        onClick={() => {
                          window.open(
                            `${BASE_URL_LOCAL}scan/${trn_id}`,
                            "_blank"
                          );
                        }}
                        className="blue-btn"
                      >
                        Click to Capture ID
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div
                className={`pb-5 ${
                  useWindowWidth.windowWidth > 776 ? "m-t-m-50" : "col-md-12"
                }`}
              >
                {true && (
                  <div className="d-flex align-items-center justify-content-around">
                    {!viewOnlyFlag && useWindowWidth.windowWidth > 776 && (
                      <>
                        {useWindowWidth.windowWidth < 1024 && (
                          <button
                            onClick={() => {
                              window.open(
                                `${BASE_URL_LOCAL}scan/${trn_id}`,
                                "_blank"
                              );
                            }}
                            className="blue-btn"
                            style={{ maxWidth: 110, fontSize: 16 }}
                          >
                            Capture ID
                          </button>
                        )}
                        <div className="q-img text-center">
                          <h5>Scan ID on Mobile</h5>
                          <div className="q-inn-img">
                            <QRCode
                              value={`${BASE_URL_LOCAL}scan/${trn_id}/?token=${token}`}
                              size={120}
                            />
                          </div>
                        </div>
                        <div class="wrapper">
                          <div class="line"></div>
                          <div class="wordwrapper">
                            <div class="word">or</div>
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      className={`${
                        useWindowWidth.windowWidth < 821 ? "w-100" : ""
                      }`}
                    >
                      <span className="sub-title mb-2">Front Side:</span>
                      {watch("identification_data.file") !== null ? (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <img
                            src={`${hasSSToken()}?token=${token}`}
                            className="img-thumbnail cursor-pointer"
                            alt=""
                            width={150}
                            height={150}
                            onClick={() =>
                              window
                                .open(
                                  `${hasSSToken(false)}?token=${token}`,
                                  "_blank"
                                )
                                .focus()
                            }
                          />

                          <button
                            type="button"
                            onClick={() => {
                              setDeleteDocumentType("application_document_id");
                              setOpen(true);
                            }}
                            title={`Delete`}
                            className="btn btn-link btn-sm text-danger"
                          >
                            <BiTrash /> Delete
                          </button>
                        </div>
                      ) : (
                        <UploadFile
                          register={register("identification_data.file")}
                          label="Upload Front"
                          className="mr-b10"
                          documentId={watch(
                            "identification_data.documenttype_id"
                          )}
                          SourceId={1}
                          multiple={true}
                        />
                      )}
                      {watch("identification_data.documenttype_id") == 2 && (
                        <>
                          <span className="sub-title mb-2">Back Side:</span>
                          {watch("identification_data.backfile") !== null ? (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                src={`${hasSSTokenBack()}?token=${token}`}
                                className="img-thumbnail cursor-pointer"
                                alt=""
                                width={150}
                                height={150}
                                onClick={() =>
                                  window
                                    .open(
                                      `${hasSSTokenBack(false)}?token=${token}`,
                                      "_blank"
                                    )
                                    .focus()
                                }
                              />

                              <button
                                type="button"
                                onClick={() => {
                                  setDeleteDocumentType("BarcodeDocumentID");
                                  setOpen(true);
                                }}
                                title={`Delete`}
                                className="btn btn-link btn-sm text-danger"
                              >
                                <BiTrash /> Delete
                              </button>
                            </div>
                          ) : (
                            <UploadFile
                              verifyDetails={verifyDetails}
                              label="Upload Back"
                              className="mr-b10 "
                              documentId={13}
                              disable={
                                watch("identification_data.file") == null
                              }
                              SourceId={1}
                              multiple={true}
                            />
                          )}
                          {watch("identification_data.file") == null && (
                            <p
                              className="small text-warning text-center"
                              style={{ width: 140 }}
                            >
                              Upload Front Side first{" "}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              {nameMatched?.applicantNameMatched === false ? (
                <div className="mt-1 mb-1">
                  <Alert severity="warning">
                    Our system detected that the name found on the ID document
                    you uploaded may not match the information entered on your
                    application. If this is not your ID, then remove and
                    reupload the correct ID. Otherwise, please confirm which
                    information is correct.
                  </Alert>
                </div>
              ) : (
                <></>
              )}
              {watch("identification_data.documenttype_id") && (
                <Stack
                  sx={{ width: "100%" }}
                  style={{ textTransform: "capitalize", marginTop: "10px" }}
                  spacing={2}
                >
                  <ErrorMessage
                    errors={formState.errors}
                    name={`identification_data.file`}
                    render={({ message }) => {
                      if (message)
                        return <Alert severity="error">{message}</Alert>;

                      return <></>;
                    }}
                  />

                  <ErrorMessage
                    errors={formState.errors}
                    name={`identification_data.backfile`}
                    render={({ message }) => {
                      if (message)
                        return <Alert severity="error">{message}</Alert>;

                      return <></>;
                    }}
                  />
                </Stack>
              )}
              {nameMatched?.applicantDOBMatched === false && (
                <div className="mt-1 mb-1">
                  <Alert severity="warning">
                    Our system detected from the ID you uploaded a DOB different
                    than what you entered. Please carefully review and confirm
                    your correct date of birth (DOB).
                  </Alert>
                </div>
              )}
            </div>
          </div>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure want to delete document ?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Warning! Once deleted cannot revert back .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                No
              </Button>
              <Button
                onClick={async () => {
                  setTimeout(() => {
                    if (deleteDocumentType == "application_document_id") {
                      deleteDocumentHandler(
                        getValues("identification_data.application_document_id")
                      );
                    } else {
                      deleteDocumentHandler(
                        getValues("identification_data.BarcodeDocumentID")
                      );
                    }
                  }, 200);
                  setOpen(false);

                  setOpen(false);
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {props?.data?.validation_message &&
            props?.data?.validation_message.map((item) => {
              if (!item.applicant.message) return null;
              return (
                <Alert className="m-1" severity="warning">
                  {item.applicant.message}
                </Alert>
              );
            })}
        </>
      )}
      {watch(`identification_data.availableToUpload_id`) == "No" && (
        <>
          <RadioInputField
            options={[
              {
                value:
                  "I don't currently have access to document(s), but can provide later",
              },
              {
                value: "I do not have proof of identification",
              },
              {
                value: "other",
                label: "Other",
              },
            ]}
            name="identification_data.no_doc_reason"
          />
          {watch("identification_data.no_doc_reason") == "other" && (
            <div className="">
              <InputField
                label="Reason"
                register={register(`identification_data.other_reason`)}
                placeholder="Reason"
              />
              <div style={{ height: "10px" }} />
            </div>
          )}
        </>
      )}
      {viewOnlyFlag ||
        (nextSection && (
          <Button
            onClick={() => {
              navigate("#address");
            }}
            variant="contained"
            endIcon={<AiOutlineArrowDown />}
          >
            Next Tab
          </Button>
        ))}
    </AccordionDetails>
  );
};

export default ProofOfIdentity;
