import React, { useRef, useState, useEffect, createRef } from "react";
import SignatureCanvas from "react-signature-canvas-react17-compatible";
import Modal from "@material-ui/core/Modal";
import { useScreenshot } from "use-react-screenshot";
import { useFormContext } from "react-hook-form";
import { useGetMasterRegistrationDataQuery } from "../../../services/registrationApi";
import { useSelector } from "react-redux";

const ESignature = (props) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [signatureEnd, setSignatureEnd] = useState(false);
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [signatureMatchedError, setSignatureMatchedError] = useState(false);
  const [text, setText] = useState("");
  const signatureRef = useRef();
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const { data, isLoading, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });

  const submitData = () => {
    if (props.signatureType === "pad") {
      let data = signatureRef.current.getCanvas().toDataURL();
      console.log("Signature Data", data);
      props.setSignatureImage(data);
      props.submit(data);
    } else {
      props.setSignatureType("text");

      getImage();
    }
  };
  const getImage = async () => {
    let textImage = await takeScreenshot(ref.current);
    props.submit(textImage);
  };

 
  function areNamesMatching(fname,lname, signText) {
    // Remove leading/trailing spaces and split names into words
    const nameWords1 = [fname,lname];
    const nameWords2 = signText.trim().split(' ').join('').toLowerCase();
    // this is case if user put name as same as register form
    for (let n of nameWords1) {
      if (nameWords2.includes(n.toLowerCase().trim())) {
        console.log(n);
        return true;
      }
    }
    return false;
  }


  const validateApplicantSignature = () => {
    if (areNamesMatching(props?.applicantFirstName,props?.applicantLastName,text)) {
      submitData();
      return;
    }
    setSignatureMatchedError(true);
    setTimeout(() => {
      setSignatureMatchedError(false);
      return;
    }, 2000);
  };

  return (
    <Modal
      open={true}
      disableBackdropClick={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable" style={{maxHeight: "inherit"}}>
        <div
          className="modal-content"
          style={{ backgroundColor: "white", width: "400px", height: "auto" }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel" style={{padding:"0px 8px"}}>
              E-Signature
            </h5>
          </div>
          <div className="text-center mr-b10 mt-2">
            <button
              className={`mr-r10 ${props.signatureType == "text"
                ? "btn btn-info"
                : "btn btn-secondary"
                }`}
              onClick={() => {
                props.setSignatureImage("");
                props.setSignatureType("text");
                props.setSignatureEnd(false);
                props.setSignatureTyped(true);
              }}
            >
              Type
            </button>

            {/* <button className="blue-btn mr-r10" onClick={() => signatureRef.current.clear()}>Clear</button> */}

            <button
              className={`mr-r10 ${props.signatureType == "pad"
                ? "btn btn-info"
                : "btn btn-secondary"
                }`}
              onClick={() => {
                props.setSignatureImage("");
                props.setSignatureType("pad");
                props.setSignatureEnd(true);
                props.setSignatureTyped(true);
              }}
            >
              Draw
            </button>
          </div>
          <>
            {props.signatureType === "pad" ? (
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 300,
                  height: 280,
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  props.setSignatureEnd(false);
                  props.setSignatureTyped(false);
                  setTimeout(() => {
                    console.log("SIGNATURE END", props.signatureEnd);
                  }, 5000);
                }}
                ref={signatureRef}
              />
            ) : (
              <div
                style={{ width: "100%", height: 280 }}
                className="pl-2 pr-2 "
              >
                <select
                  className="form-select form-select-sm "
                  aria-label=".form-select-sm example"
                  sub
                  style={{ height: "40px" }}
                  name="fonts"
                  onChange={(e) => {
                    props.setSignatureFont(e.target.value);
                  }}
                >
                  <option selected>Select Font</option>
                  <option value="nautigalFont" className="nautigalFont">
                    Signature 1
                  </option>
                  <option
                    value="islandMomentsFont"
                    className="islandMomentsFont"
                  >
                    Signature 2
                  </option>
                  <option value="cedarvilleFont" className="cedarvilleFont">
                    Signature 3
                  </option>
                  <option value="licoriceFont" className="licoriceFont">
                    Signature 4
                  </option>
                  <option
                    value="dancingScriptFont"
                    className="dancingScriptFont"
                  >
                    Signature 5
                  </option>
                 
                </select>
                {props.signatureFont !== "" && (
                  <input
                    type={"text"}
                    className={`${props?.signatureFont == "Select Font"
                      ? "display-none"
                      : "form-control mt-3 ComforterBrush"
                      }`}
                    placeholder="Enter signature"
                    value={props.signatureText}
                    onChange={(e) => {
                      setText(e.target.value);
                      props.setSignatureText(e.target.value);
                      props.setSignatureTyped(false);
                    }}
                  />
                )}

                <div
                  className={`${props.signatureFont} d-flex align-items-center justify-content-center mt-4 h-50`}
                >
                  <h1 ref={ref} className="fw-bold" id="capturesignature">
                    {props.signatureText}
                  </h1>
                </div>
              </div>
            )}
          </>
          {signatureMatchedError && (
            <span className="red text-center mb-2">
              Signature name must match applicant name {props?.applicantFirstName} {props?.applicantLastName}
            </span>
          )}
          <div className="tc-check">
            <div className="cus-check red-check mr-b15 px-2">
              <input
                type="checkbox"
                name="authorized_sign"
                id="authorized_sign"
                onChange={(e) => setIsAuthorized(e.target.checked)}
              />
              <label
                htmlFor="authorized_sign"
                className="mr-b0 d-flex align-items-center"
              >
                <span className="mr-r10"></span>{" "}
                <p
                  style={{
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#606060",
                  }}
                >
                  By signing, I acknowledge that I am “
                  {data?.records?.first_name} {data?.records?.last_name}” or I
                  am an authorized to sign on their behalf.
                </p>
              </label>
            </div>
          </div>
          <div className="text-center mr-b10">
            <button
              className="btn btn-secondary mr-r10"
              onClick={() => {
                props.setSignatureText("");
                props.setSignatureFont("");
                props.setSignatureImage("");
                props.cancelOperation(true);
                props.setSignatureEnd(false);
                props.setSignatureTyped(false);
                setSignatureMatchedError(false);
              }}
            >
              Cancel
            </button>

            {/* <button className="blue-btn mr-r10" onClick={() => signatureRef.current.clear()}>Clear</button> */}
            <button
              // disabled={props.signatureTyped}
              disabled={props.signatureTyped ? true : !isAuthorized ? true : false}
              className="btn btn-info mr-l10"
              onClick={() => {
                //TODO: Check for name validation if else
                if (text) {
                  validateApplicantSignature();
                } else {
                  submitData();
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ESignature;
