import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegisterOffice from "./containers/register/registerOffice";
import Instruction from "./containers/instruction/instruction";
import Identification from "./containers/identification/identification";
import IdentificationView from "./containers/identification/identification-view";
import Login from "./containers/login/login";
import ResetPassword from "./containers/resetPassword/resetPassword";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import PrivateRoute, {
  PrivateRouteInstructions,
  PrivateRouteViewOnly,
  RouteCheckGuard,
  RouteGuardLoginRegistration,
} from "./PrivateRoute";
import IdentificationDetails from "./components/identification/identification-details/identification-details";
import ApplicantDetails from "./components/identification/applicant-details/applicant-details";
import IncomeBanking from "./components/identification/income-banking/income-banking";
import ConsentPayment from "./components/identification/consent-payment/consent-payment";
import Success from "./containers/Success";
import ViewConsent from "./containers/ViewConsent";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sideNavAction } from "./store/identification/sideMenuSlice";
import CameraScan from "./containers/camera-scan/CameraScan";
import BackBarcodeScan from "./containers/backbarcode-scan/BackBarcodeScan";
import ScanHome from "./containers/scan-home/ScanHome";
import CameraScanBack from "./containers/camera-scan-back/cameraScanBack";
import ScanMenu from "./containers/scan-menu/ScanMenu";
import ScanSuccess from "./containers/scan-success/ScanSuccess";
import PredectPaystub from './containers/ml/PredictPaystub';
import PredictBankStatement from "./containers/ml/PredictBanckStatement";
import CameraRoot from "./containers/Camera";

function App() {
  const dispatch = useDispatch();
  // const useWindowWidth = useSelector(
  //   (state) => state.identification.sideMenuSlice
  // );

  const updateWindowWidth = () => {
    dispatch(sideNavAction.windowWidth(window.innerWidth));
  };

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    // console.log("Check Width Function App Line 38", useWindowWidth.windowWidth);
    window.addEventListener("resize", updateWindowWidth);

    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<RouteCheckGuard />} path="/Office/*" />
          <Route element={<RouteCheckGuard />} path="/Agent/*" />
          <Route element={<RouteCheckGuard />} path="/Unit/*" />
          <Route element={<RouteCheckGuard />} path="/Floor/*" />
          <Route element={<RouteCheckGuard />} path="/Property/*" />
          <Route element={<>Office Guest card</>} path="/Office/GuestCard/*" />
          <Route element={<> Office Docupload</>} path="/Office/Docupload/*" />
          <Route element={<>Property Guest card</>} path="/Property/GuestCard/*" />
          <Route element={<>Property Docupload</>} path="/Property/Docupload/*" />
          <Route path="/registration" element={<RouteGuardLoginRegistration />}>
            <Route path="" element={<RegisterOffice />} />
          </Route>
          <Route path="camera-avi" element={<CameraRoot/>}/>
          {/* <Route path="/registration" element={<RegisterOffice />} /> */}
          <Route path="/identification" element={<PrivateRoute />}>
            <Route path="" element={<Identification />}>
              <Route path="" index element={<IdentificationDetails />} />
              <Route path="applicantDetail" element={<ApplicantDetails />} />
              <Route path="incomeDetail" element={<IncomeBanking />} />
              <Route path="payment" element={<ConsentPayment />} />
            </Route>
          </Route>

          <Route path="/view" element={<PrivateRouteViewOnly />}>
            <Route path="" element={<IdentificationView />}>
              <Route path="" index element={<IdentificationDetails />} />
              <Route path="applicantDetail" element={<ApplicantDetails />} />
              <Route path="incomeDetail" element={<IncomeBanking />} />
              <Route path="payment" element={<ConsentPayment />} />
            </Route>
          </Route>

          <Route path="/instruction" element={<PrivateRouteInstructions />}>
            <Route path="" element={<Instruction />} />
          </Route>
          <Route exact path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />

          <Route path="/reset-password/:id" element={<ResetPassword />} />

          <Route
            path="/unauthorizedAccess"
            element={<ScanSuccess unauthacess={true} />}
          />

          <Route path="success" element={<Success />} />

          <Route path="/viewconsent" exact element={<ViewConsent />} />
          <Route path="/ml/predectPaystub" element={<PredectPaystub />} />
          <Route path="/ml/predectBankStatement" element={<PredictBankStatement />} />

          <Route path="/scan/:id">
            <Route path="" element={<ScanHome />} />
            <Route path="menu" element={<ScanMenu />} />
            <Route path="camera/:docId" element={<CameraScan />} />
            <Route path="back/:docId" element={<BackBarcodeScan />} />
            <Route path="camera_back/:docId" element={<CameraScanBack />} />
            <Route path="success" element={<ScanSuccess />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
