import React from "react";
import { useFormContext } from "react-hook-form";
import UploadFile from "../../../common/uploadFile/uploadFile";
import FileIcon from "../../../common/FileIcon";
import { useGetIncomeBankingQuery } from "../../../../services/identificationApi";
import { useSelector } from "react-redux";
import { RiInformationLine } from "react-icons/ri";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const OtherDocuments = (props) => {
  const [taxOtherDocs, setTaxOtherDocs] = React.useState(false);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const { data } = useGetIncomeBankingQuery(trn_id);
  const { control, register, watch, getValues } = useFormContext();
  return (
    <div className="site-cus-tab" id="od">
      <a
        data-bs-toggle="collapse"
        href="#otherDocCollapse"
        role="button"
        aria-expanded="false"
        aria-controls="otherDocCollapse"
      >
        <div
          className={`${
            useWindowWidth.windowWidth < 766
              ? "display-none xs-title-acc"
              : "xs-title-acc"
          }`}
        >
          <h3>Other Documents</h3>
        </div>
      </a>

      <div id="otherDocCollapse" className="p-d-f-wrap white-box ps-2 pe-2">
        <div className="od-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="note-block text-center mr-b30">
                <h5 className="justify-content-center">
                  <span>Note: </span>
                  &nbsp; If you want to upload any other document, you can do it
                  here
                </h5>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="d-flex justify-content-between align-items-center mr-t20">
                <div className="ssn-text-block">
                  <h4>
                    Tax Documents OR Other Documents:
                    {/* <button type="button" className="info-btn">
                      <img src="images/info-icon.png" alt="" />
                    </button> */}
                    <RiInformationLine
                      className="ms-2 cursor-pointer"
                      fill="#D3AF51"
                      size={20}
                      onClick={() => setTaxOtherDocs(true)}
                    />
                  </h4>
                  <h5>(W2, 1099, tax return,etc)</h5>
                  <small className="highlight-text-background">
                    Click the (i) button above for details
                  </small>
                </div>
                <div className="ssn-btn">
                  <UploadFile
                    label="Upload Back"
                    className="mr-b10"
                    documentId={5}
                    SourceId={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={` ${
              useWindowWidth.windowWidth < 986 && `justify-content-center`
            } d-flex flex-wrap`}
          >
            {data.other_documents.map((item, index) => {
              return (
                <FileIcon key={item.application_document_id} fileObj={item} />
              );
            })}
          </div>
        </div>
      </div>
      <Dialog
        open={taxOtherDocs}
        onClose={() => {
          setTaxOtherDocs(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Only upload clear, complete, straight documents and that original
            PDF's downloaded from an HR or payroll website work best.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setTaxOtherDocs(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OtherDocuments;
