import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";

import { yupResolver, yupArray } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import InputField from "../../components/registration/Inputs/InputField";
import InputSimplePassword from "../../components/registration/Inputs/InputSimplePassword";
import { FormProvider, useForm } from "react-hook-form";

import Modal from "@material-ui/core/Modal";
import ForgotPassword from "../../components/forgotPassword/forgotPassword";
import { toast, ToastContainer } from "react-toastify";
import {
  useForgotUserMutation,
  useLoginUserMutation,
} from "../../services/registrationApi";
import { Outlet, Link, useNavigate, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  urlGeneratedAts,
  trnId,
  applicationType,
  token,
  refreshToken,
} from "../../store/registration/logindSlice";
import identificationApi from "../../services/identificationApi";
import { logout_url } from "../../config";

export const getUrlAndTransId = (path) => {
  const splitPath = path.split("/");
  splitPath.shift();
  if (splitPath[0] === "identification") {
    splitPath.shift();
  }
  let trn_id = null;
  let fullPath = null;
  if (
    (splitPath[0] === "Office" && splitPath.length === 5) ||
    (splitPath[0] === "Unit" && splitPath.length === 6) ||
    (splitPath[0] === "Floor" && splitPath.length === 6) ||
    (splitPath[0] === "Property" && splitPath.length === 5)
  ) {
    fullPath = splitPath.slice(0, splitPath.length - 1).join("/");
    trn_id = splitPath[splitPath.length - 1];
  } else {
    fullPath = splitPath.slice(0, splitPath.length).join("/");
    trn_id = 0;
  }
  return {
    url: fullPath,
    trn_id: trn_id,
    applicationType: splitPath[0],
  };
};

const Login = (props) => {
  const [forgotFlag, setForgotFlag] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loginUser, loginUserResult] = useLoginUserMutation();

  const schema = yup.object().shape({
    password: yup.string().required("Required"),
    email: yup.string().typeError("Required").required("Required"),
  });

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Docuverus";
  }, []);

  useEffect(() => {
    //debugger;
    const getId = new URLSearchParams(location.search).get("trn_id");
    const url = new URLSearchParams(location.search).get("url");
    console.log("🚀 ~ file: PrivateRoute.js ~ line 129 ~ login ~ url", url);
    const searchToken = new URLSearchParams(location.search).get("token");
    console.log(
      "🚀 ~ file: PrivateRoute.js ~ line 131 ~ login ~ searchToken",
      searchToken
    );
    const refreshToken = new URLSearchParams(location.search).get(
      "refreshToken"
    );
    console.log(
      "🚀 ~ file: PrivateRoute.js ~ line 133 ~ login ~ refreshToken",
      refreshToken
    );
    console.log("🚀 ~ file: PrivateRoute.js ~ line 128 ~ login ~ getId", getId);
    if (getId && url && searchToken) {
      dispatch(token(searchToken));
      dispatch(urlGeneratedAts(url));
      dispatch(trnId(getId));
      dispatch(
        applicationType(
          getUrlAndTransId("/" + url + "/" + getId)?.applicationType
        )
      );

      dispatch(
        identificationApi.endpoints.getSectionStatus.initiate(getId, {
          track: false,
          refetch: true,
        })
      ).then(() => { });

      navigate("/instruction");
    }
  }, [location]);

  const onSubmit = (data) => {
    console.log(data);
    let tempObj = data;

    loginUser({
      ...tempObj,
    })
      .then((response) => {
        console.log(response);
        if (response?.data && response.data.status == 200) {

          dispatch(
            identificationApi.endpoints.getApplicantStatus.initiate(
              response.data.records.trn_id,
              { track: false, refetch: true }
            )
          ).then((res) => {
            dispatch(
              identificationApi.endpoints.getSectionStatus.initiate(
                response.data.records.trn_id,
                { track: false, refetch: true }
              )
            ).then(() => {
              dispatch(token(response.data.records.token));
              dispatch(refreshToken(response?.data?.records?.refreshToken));
              dispatch(
                urlGeneratedAts(
                  getUrlAndTransId(
                    "/" +
                    response.data.records.url +
                    "/" +
                    response.data.records.trn_id
                  )?.url
                )
              );
              dispatch(
                trnId(
                  getUrlAndTransId(
                    "/" +
                    response.data.records.url +
                    "/" +
                    response.data.records.trn_id
                  )?.trn_id
                )
              );
              dispatch(
                applicationType(
                  getUrlAndTransId(
                    "/" +
                    response.data.records.url +
                    "/" +
                    response.data.records.trn_id
                  )?.applicationType
                )
              );
              toast.success(response.data.message, { pauseOnFocusLoss: false });
              sessionStorage.setItem('trn_id', getUrlAndTransId(
                "/" +
                response.data.records.url +
                "/" +
                response.data.records.trn_id
              )?.trn_id)
              navigate("/instruction");
            });
          });
        } else {
          toast.error(response.error.data.message, { pauseOnFocusLoss: false });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Invalid Credentials.", { pauseOnFocusLoss: false });
      });
  };

  return (
    <>
      <section className="login-wrap">
        <div className="login-cell">
          <div className="login-block">
            <div className="l-logo-block text-center">
              <img src={Logo} alt="sealcon" title="sealcon" />
            </div>

            <div className="auth-form mr-t30">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="form-group cus-input-group mr-b20">
                    <InputField
                      label="Email or Username"
                      register={methods.register("email")}
                    />
                  </div>
                  <div className="form-group cus-input-group mr-b20">
                    <InputSimplePassword
                      label="Password"
                      register={methods.register("password")}
                    />
                  </div>
                  <div className="f-link text-right">
                    <a href="#" onClick={(e) => setForgotFlag(true)}>
                      Forgot Password?
                    </a>
                  </div>
                  <button type="submit" className="l-btn">
                    Log in
                  </button>
                </form>
              </FormProvider>
            </div>

            <div className="l-foot-links mr-t20">
              <ul className="mx-auto">
                <li>
                  <a
                    href={logout_url+"/DataPolicy/Index"}
                    target="_blank"
                  >
                    Data Policy
                  </a>
                </li>

                <li>
                  <a
                    href={logout_url+"/TermsOfUse/Index"}
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a href="#">Version</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <DevTool control={methods.control} />

      <Modal
        open={forgotFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setForgotFlag(false)}
        onAbort={() => setForgotFlag(false)}
      >
        <ForgotPassword emit={(data) => setForgotFlag(false)} />
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Login;
