/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
//import $ from "jquery";

import {
  useGetIdentificationDataQuery,
  useGetDetailsQuery,
  useGetSectionStatusQuery,
} from "../../services/identificationApi";
import Loader from "react-loader-spinner";
import { useGetMasterRegistrationDataQuery } from "../../services/registrationApi";
import SuperHeader from "../../components/common/superHeader/superHeader";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  formSubmitStatus,
  formSubmitStatusRest,
} from "../../store/registration/fromSubmitSlice";
import { sideNavAction } from "../../store/identification/sideMenuSlice";
import { BsCheckLg } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { tabsStateAction } from "../../store/identification/tabStateSlice";
import { protocol } from "../../config";

const Identification = (props) => {
  const [disableNextBtn, setDisableNext] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { watch } = useForm();

  const location = useLocation();
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const { data, isLoading, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });
  let identificationData = useGetIdentificationDataQuery(trn_id);
  let sectionCompleteStatus = useGetSectionStatusQuery(trn_id);

  dispatch(sideNavAction.applicationStatusState(sectionCompleteStatus.data));
  let contextualData = useGetDetailsQuery(trn_id);
  const application_status = useSelector(
    (state) => state.identification.sideMenuSlice
  );

  const subTabs = useSelector((state) => state.identification.tabsStateSlice);

  useEffect(() => {
    console.log(" ", trn_id);
    if (
      application_status?.applicationStatus?.identification_details_status ===
      false
    ) {
      return navigate("/identification");
    }
    if (
      application_status?.applicationStatus?.applicant_details_status === false
    ) {
      dispatch(tabsStateAction.sideNavIdentificationReducer(true));
      return navigate("applicantDetail");
    }
    if (
      application_status?.applicationStatus?.income_banking_status === false
    ) {
      dispatch(tabsStateAction.sideNavIdentificationReducer(true));
      dispatch(tabsStateAction.sideNavApplicantReducer(true));
      return navigate("incomeDetail");
    }
    if (
      application_status?.applicationStatus?.consent_payment_status === false
    ) {
      dispatch(tabsStateAction.sideNavIdentificationReducer(true));
      dispatch(tabsStateAction.sideNavApplicantReducer(true));
      dispatch(tabsStateAction.sideNavIncomeReducer(true));
      return navigate("payment");
    }
  }, [application_status.applicationStatus]);

  if (isLoading && identificationData.isLoading && contextualData.isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  else
    return (
      <main className="body-wrap">
        <SuperHeader />
        <section className="top-white-row">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-md-8 col-sm-6">
                <div className="w-r-logo">
                  <img
                    src={`${data?.records?.subrciber_logo}`}
                    className="subscriber-logo"
                    alt=""
                    title=""
                  />
                  <span className="res-company-name">
                    {data?.records?.subscriber_name}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div
                  className="t-w-appid text-right d-flex"
                  style={{ justifyContent: "end" }}
                >
                  <h5 className="mr-r10">
                    Name:{" "}
                    <span>
                      {" "}
                      {data?.records?.first_name} {data?.records?.last_name}
                    </span>
                  </h5>
                  <h5>
                    Application ID:{" "}
                    <span>{data?.records?.application_number}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="application-body-wrap">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="id-steper-wrap">
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-3">
                      <div className="tabs-nav-block white-box xs-tabs-btn">
                        <div className="s-tab-list">
                          <ul className="nav nav-tabs flex-column">
                            <li
                              className={`nav-item ${
                                location.pathname === "/identification" &&
                                "site-active"
                              } ${
                                location.pathname === "/identification/" &&
                                "site-active"
                              } `}
                            >
                              <a
                                className={`nav-link`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // dispatch(formSubmitStatusRest());
                                  navigate("/identification");
                                  // dispatch(formSubmitStatus());
                                }}
                              >
                                <span
                                  className={` ${
                                    application_status?.applicationStatus
                                      ?.identification_details_status === true
                                      ? "s-no-complete s-no-com"
                                      : "s-no-incomplete s-no-in"
                                  }`}
                                >
                                  {subTabs.sideNavIdentification === true ? (
                                    <BsCheckLg />
                                  ) : (
                                    <>1</>
                                  )}
                                </span>
                                <span
                                  className={`${
                                    application_status?.applicationStatus
                                      ?.identification_details_status === true
                                      ? "complete-step"
                                      : ""
                                  }`}
                                >
                                  Identification Details
                                </span>
                              </a>
                              <div className="nav-sub-tabs">
                                <ul>
                                  <li className="active-sub">
                                    <a
                                      className={
                                        `${watch(
                                          "identification_data.first_name"
                                        )}` &&
                                        `${watch(
                                          watch("identification_data.last_name")
                                        )}` &&
                                        `${watch(
                                          watch("identification_data.last_name")
                                        )}`
                                      }
                                      href="#personal-details"
                                    >
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Personal Detail
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.personalDetailsStatus &&
                                      "active-sub"
                                    }
                                  >
                                    <a href="#poi">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Proof of Identity
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.ProofOfIdentityStatus &&
                                      "active-sub"
                                    }
                                  >
                                    <a href="#address">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Address
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.AddressStatus && "active-sub"
                                    }
                                  >
                                    <a href="#ssn">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      SSN
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              className={`nav-item ${
                                location.pathname ===
                                  "/identification/applicantDetail" &&
                                "site-active"
                              } ${
                                location.pathname ===
                                  "/identification/applicantDetail/" &&
                                "site-active"
                              }`}
                            >
                              <a
                                className={`nav-link`}
                                id="agent-tab"
                                onClick={(e) => {
                                  e.preventDefault();
                                  //dispatch(formSubmitStatusRest());

                                  // if (
                                  //   application_status?.applicationStatus
                                  //     ?.identification_details_status === true
                                  // ) {
                                  //   navigate("applicantDetail");
                                  // } else {
                                  if (subTabs.sideNavIdentification === true)
                                    return navigate("applicantDetail");
                                  dispatch(formSubmitStatus());
                                  //}
                                  //navigate("applicantDetail");
                                }}
                              >
                                <span
                                  className={`${
                                    application_status?.applicationStatus
                                      ?.applicant_details_status === true
                                      ? "s-no-complete s-no-com"
                                      : "s-no-incomplete s-no-in"
                                  }`}
                                >
                                  {subTabs.sideNavApplicant == true ? (
                                    <BsCheckLg />
                                  ) : (
                                    <>2</>
                                  )}
                                </span>
                                <span
                                  className={`${
                                    application_status?.applicationStatus
                                      ?.applicant_details_status === true
                                      ? "complete-step"
                                      : ""
                                  }`}
                                >
                                  Applicant Details
                                </span>
                              </a>
                              <div className="nav-sub-tabs">
                                <ul>
                                  <li className="active-sub">
                                    <a href="#ad">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Applicant Details
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.ApplicantDetailStatus &&
                                      "active-sub"
                                    }
                                  >
                                    <a href="#contacts">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Contacts
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.ContactsStatus && "active-sub"
                                    }
                                  >
                                    <a href="#fd">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Financial Details
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.FinantialDetailStatus &&
                                      "active-sub"
                                    }
                                  >
                                    <a href="#others">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Other Details
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              className={`nav-item ${
                                location.pathname ===
                                  "/identification/incomeDetail" &&
                                "site-active"
                              } ${
                                location.pathname ===
                                  "/identification/incomeDetail/" &&
                                "site-active"
                              } `}
                            >
                              <a
                                className={`nav-link`}
                                id="desired-tab"
                                onClick={(e) => {
                                  e.preventDefault();
                                  //  dispatch(formSubmitStatusRest());

                                  // if (
                                  //   application_status?.applicationStatus
                                  //     ?.applicant_details_status === true
                                  // ) {
                                  //   navigate("incomeDetail");
                                  // } else {
                                  if (
                                    subTabs.sideNavIdentification === true &&
                                    subTabs.sideNavApplicant == true
                                  )
                                    return navigate("incomeDetail");
                                  dispatch(formSubmitStatus());
                                  //  }
                                  //navigate("incomeDetail");
                                }}
                              >
                                <span
                                  className={` ${
                                    application_status?.applicationStatus
                                      ?.income_banking_status === true
                                      ? "s-no-complete s-no-com"
                                      : "s-no-incomplete s-no-in"
                                  }`}
                                >
                                  {subTabs.sideNavIncome == true ? (
                                    <BsCheckLg />
                                  ) : (
                                    <>3</>
                                  )}
                                </span>
                                <span
                                  className={`${
                                    application_status?.applicationStatus
                                      ?.income_banking_status === true
                                      ? "complete-step"
                                      : ""
                                  }`}
                                >
                                  Income & Banking
                                </span>
                              </a>
                              <div className="nav-sub-tabs">
                                <ul>
                                  <li className="active-sub">
                                    <a href="#ei">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Employment Income
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.IncomeStatus && "active-sub"
                                    }
                                  >
                                    <a href="#other-in">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Other Income
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.OtherIncomeStatus && "active-sub"
                                    }
                                  >
                                    <a href="#bank-info">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Banking Information
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.BankStatus && "active-sub"
                                    }
                                  >
                                    <a href="#od">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Other Documets
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              className={`nav-item ${
                                location.pathname ===
                                  "/identification/payment" && "site-active"
                              } ${
                                location.pathname ===
                                  "/identification/payment/" && "site-active"
                              }  `}
                            >
                              <a
                                className={`nav-link `}
                                onClick={(e) => {
                                  e.preventDefault();
                                  //dispatch(formSubmitStatusRest());
                                  //navigate("payment");
                                  // if (
                                  //   application_status?.applicationStatus
                                  //     ?.income_banking_status === true
                                  // ) {
                                  //   navigate("payment");
                                  // } else {
                                  if (
                                    subTabs.sideNavIdentification === true &&
                                    subTabs.sideNavApplicant == true &&
                                    subTabs.sideNavIncome == true
                                  )
                                    return navigate("payment");
                                  dispatch(formSubmitStatus());
                                  //}
                                }}
                              >
                                <span
                                  className={`${
                                    application_status?.applicationStatus
                                      ?.consent_payment_status === true
                                      ? "s-no-complete s-no-com"
                                      : "s-no-incomplete s-no-in"
                                  }`}
                                >
                                  {application_status?.applicationStatus
                                    ?.consent_payment_status === true ? (
                                    <BsCheckLg />
                                  ) : (
                                    <>4</>
                                  )}
                                </span>
                                <span
                                  className={`${
                                    application_status?.applicationStatus
                                      ?.consent_payment_status === true
                                      ? "complete-step"
                                      : ""
                                  }`}
                                >
                                  Consent & Payment
                                </span>
                              </a>
                              <div className="nav-sub-tabs">
                                <ul>
                                  <li className="active-sub">
                                    <a href="#tc">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Terms & Conditions
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      subTabs.termsStatus && "active-sub"
                                    }
                                  >
                                    <a href="#ps">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Payment & Signature
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9 xs-tabs-bg">
                      <div className="tabs-wrap">
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="f-a-btn text-right">
                  <button
                    disabled={disableNextBtn}
                    className="blue-btn"
                    style={disableNextBtn ? { background: "lightgrey" } : {}}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(formSubmitStatus());
                       setDisableNext(true);
                      if (
                        location.pathname == "/identification/payment" ||
                        location.pathname == "/identification/payment/"
                      ) {
                        setTimeout(() => setDisableNext(false), 10000);
                      } else setTimeout(() => setDisableNext(false), 3000);
                    }}
                  >
                    {location.pathname == "/identification/payment" ||
                    location.pathname == "/identification/payment/"
                      ? "Submit "
                      : "Next "}{" "}
                    &nbsp;
                    {(location.pathname !== "/identification/payment" ||
                      location.pathname == "/identification/payment/") && (
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.546 6.99138L7.18202 1.62738L8.59602 0.213379L16.374 7.99138L8.59602 15.7694L7.18202 14.3554L12.546 8.99138H0.374023V6.99138H12.546Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    );
};

export default Identification;
