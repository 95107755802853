import moment from "moment";
import React from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import SuperHeader from "../components/common/superHeader/superHeader";
import { useGetTermandCondtionQuery, useGetViewConsentQuery } from "../services/identificationApi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { AiFillWarning } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { protocol } from "../config";
import parse from 'html-react-parser';

function ViewConsent() {
  const navigate = useNavigate();
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { data, isLoading } = useGetViewConsentQuery(trn_id);
  const { data: termandCondtion, error, isLoading: isLoadingTermandCondtion } = useGetTermandCondtionQuery(trn_id)
  console.log("Check get Concent Details", data);
  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  else {
    return (
      <div className="app-bg ">
        <SuperHeader />
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-12 ms-4 mt-2 "
              style={{ boxSizing: "border-box" }}
            >
              <IoMdArrowRoundBack
                className="cursor-pointer"
                onClick={() => navigate(-1)}
                size={25}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="tc-body" id="collapseExample">
                <div className="static-info pt-4">
                  <div className="white-box pd20">
                    <h4 className="app-bg pt-2 pb-2 ps-2 rounded">
                      Agreed to Terms, Conditions & Signature
                    </h4>
                    {termandCondtion?.records?.text && (<>
                      {parse(termandCondtion?.records?.text)}
                    </>)}

                    {termandCondtion?.records?.subscriber_text && (<>
                      <br />
                      {parse(termandCondtion?.records?.subscriber_text)}
                    </>)}
                    {/* <p className="para-format">
                      Docuverus charges a NON-REFUNDABLE APPLICATION FEE of
                      $5.00 per applicant (the "Application Fee"), which covers
                      certain processing and screening service costs associated
                      with your application submitted herein (the
                      "Application"), such as verifying your income, checking
                      your credit, and conducting a background check. Under no
                      circumstances shall Applicant be entitled to a refund or
                      charge-back of the Application Fee. The Application Fee,
                      which must be paid at the time that the Applicant submits
                      a rental application, is separate and apart from any real
                      estate brokerage that Applicant may be responsible for
                      paying to a Broker or my have otherwise agreed to pay to a
                      Broker.
                    </p>
                    <p className="para-format">
                      Applicant represents and warrants that all of the
                      information contained in the Application is true and
                      accurate. Applicant expressly understands and agrees that
                      submitting this Application does not guarantee that it
                      will be accepted by the applicable landlord or property
                      management company. Applicant acknowledges and agrees that
                      Application acceptance is solely within the discretion of
                      landlord and/or property management company and neither
                      Docuverus, nor National Tenant Network (defined below), is
                      responsible for determining whether Applicant's
                      application is accepted or denied. Applicant further
                      acknowledges and agrees that landlord(s) and/or property
                      management company may deny Applicant's application or
                      terminate any lease agreement with Applicant if any
                      information contained herein is false, incomplete,
                      unverifiable, and/or misleading. Applicant shall not be
                      entitled to a refund or charge-back of the Application Fee
                      in the event that the application is rejected or denied
                      for any reason.
                    </p>
                    <p className="para-format">
                      As part of the application process, National Tenant
                      Network will conduct a background screening report, In
                      order for National Tenant Network and any prospective
                      landlord to process your Application, Applicant must
                      provide written consent to conduct or run certain
                      searches, inquiries, or reports, which may include
                      accessing your credit report (from Equifax, Experian or
                      TransUnion), eviction report, criminal background report,
                      sex offender report, income/employment verification,
                      residency verification and OFAC terrorist search. By
                      completing this Application and signing below, you consent
                      to National Tenant Network conducting such searches in
                      accordance with the Fair Credit Reporting Act. You are
                      also consenting to Docuverus sharing your Application with
                      National Tenant Network for the purpose of running a
                      background screening report.
                    </p>
                    <p className="para-format">
                      Applicant consents to Docuverus sharing all application
                      information and documents with Anytown Real Estate Ltd,
                      and Applicant also consents to Anytown Real Estate Ltd
                      providing a copy of Applicant's application and/or
                      background report to a prospective Landlord and/or his/her
                      respective listing agent, if applicable, in connection
                      with the rental property applied for.
                    </p>
                    <p className="para-format">
                      Federal and State Disclosures <br />
                      Click here for a Summary of Your Rights Under the Fair
                      Credit Reporting Act.
                    </p>
                    <p className="para-format">
                      Washington State applicants only: You also have the right
                      to request from the consumer reporting agency a written
                      summary of your rights and remedies under the Washington
                      Fair Credit Reporting Act.
                    </p>
                    <p className="para-format">
                      New York/Maine applicants only: You have the right to
                      inspect and receive a copy of any investigative consumer
                      report requested by the Landlord by contacting the
                      consumer reporting agency identified directly. You may
                      also contact the Company to request the name, address and
                      telephone number of the nearest unit of the consumer
                      reporting agency designated to handle inquiries, which the
                      Company shall provide within 5 days.
                    </p>
                    <p className="para-format">
                      New York City applicants only: Pursuant to federal, state,
                      and local law: 1) If we take adverse action against you on
                      the basis of information contained in a tenant screening
                      report, we must notify you that such action was taken and
                      supply you with the name and address of the consumer
                      reporting agency that provided the tenant screening report
                      on the basis of which such action was taken; 2) if any
                      adverse action is taken against you based on information
                      contained in a tenant screening report, you have the right
                      to inspect and receive a free copy of that report by
                      contacting the consumer reporting agency: NTN-New York, PO
                      Box 1023, Turnersville, NJ 08012, Telephone: 800-422-8299;
                      3) every tenant or prospective tenant is entitled to one
                      free tenant screening report from each national consumer
                      reporting agency annually, in addition to a credit report
                      that should be obtained from www.annualcreditreport.com;
                      and 4) every tenant or prospective tenant may dispute
                      inaccurate or incorrect information contained in a tenant
                      screening report directly with the consumer reporting
                      agency.
                    </p>
                    <p className="para-format">
                      California applicants or residents only: By signing this
                      application, you also acknowledge receipt of the NOTICE
                      REGARDING BACKGROUND INVESTIGATION PURSUANT TO CALIFORNIA
                      LAW. Please contact the consumer reporting agency if you
                      would like to receive a copy of an investigative consumer
                      report or consumer credit report at no charge if one is
                      obtained by the Company whenever you have a right to
                      receive such a copy under California.
                    </p> */}
                  </div>
                </div>
                {data?.records?.length > 0 && (
                  <>
                    <div className="static-info pt-4 pb-4">
                      <div className="white-box pd20">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="flex pb-2">
                                <span>Signature:</span>
                              </div>
                              <div className="flex">
                                <img
                                  width={"80px"}
                                  height={"50px"}
                                  src={`${data?.records[0]?.file}`}
                                  alt="signature"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="flex pb-2">
                                <span>IP Address:</span>
                              </div>
                              <div className="flex">
                                <span>{`${data?.records[0].ip}`}</span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="flex pb-2">
                                <span>Application Date:</span>
                              </div>
                              <div className="flex">
                                <span>
                                  {moment(
                                    `${data?.records[0]?.InviteInfo?.CreatedOn}`
                                  ).format("MM-DD-YYYY")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="static-info pt-4 pb-4">
                      <div className="white-box pd20">
                        <div className="container">
                          <div className="row">
                            <h4 className="app-bg pt-2 pb-2 ps-2 rounded">
                              Application Payment
                            </h4>
                            <div className="col-md-4">
                              <div className="flex pb-2">
                                <span>Card holder name:</span>
                              </div>
                              <div className="flex">
                                <span>{`${data?.records[0].name_on_card}`}</span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="flex pb-2">
                                <span>Amount collected:</span>
                              </div>
                              <div className="flex">
                                <span>{`$ ${
                                  data?.records[0].amount 
                                } `}</span>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="flex pb-2">
                                <span>Payment Date:</span>
                              </div>
                              <div className="flex">
                                <span>
                                  {moment(
                                    `${data?.records[0]?.transaction_date}`
                                  ).format("MM-DD-YYYY")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-4">
                              <div className="flex pb-2">
                                <span>Card Number:</span>
                              </div>

                              <div className="flex">
                                <span>
                                  {`XXXX-XXXX-XXXX-${data?.records[0]?.card_number}`}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="flex pb-2">
                                <span>Billing address :</span>
                              </div>
                              <div className="flex">
                                <span>{`${data?.records[0].city} ${data?.records[0].state}`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewConsent;
