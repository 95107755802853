import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fromSectionSubmit:0,
  viewOnlyFlag:false
};
const fromSubmit = createSlice({
  name: "fromSubmit",
  initialState,
  reducers: {
    formSubmitStatus:(state,action)=>{
      state.fromSectionSubmit=state.fromSectionSubmit+1;
     // state.fromSectionSubmit=action.payload
    },
    formSubmitStatusRest:(state,action)=>{
      state.fromSectionSubmit=0;
     // state.fromSectionSubmit=action.payload
    },
    setViewOnlyFlag:(state,action)=>{
      state.viewOnlyFlag=action.payload
    }
  },
});

export const {formSubmitStatus,formSubmitStatusRest,setViewOnlyFlag} = fromSubmit.actions;

export default fromSubmit.reducer;
