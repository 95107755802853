import React from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";

const InputSimplePassword = (props) => {
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { control } = useFormContext();
  const { name } = props.register;
  // const { dirtyFields, errors } = useFormState({
  //   control,
  // });
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  // const error = errors[name];

  return (
    <>
      <label htmlFor={name} className={`form-label ${error && "text-danger"}`}>
        {props.label} {required && <span className="red">*</span>}
      </label>
      <input
        type="password"
        placeholder={props.placeholder || `${props.label}`}
        className={`form-control cus-input-control ${
          required && !field.value && "is-warning"
        } ${error && "is-invalid"}`}
        {...props.register}
        onBlur={(e) => {
          const dependentFun = props.triggerDependent || null;
          if (dependentFun) {
            if (!e.target.value == "") dependentFun(e);
          }
          props.register.onBlur(e);
        }}
        id={name}
      />
      <div className="invalid-feedback">{error && error.message}</div>
    </>
  );
};

export default InputSimplePassword;
