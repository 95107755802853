import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";

import { yupResolver, yupArray } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import InputField from "../../components/registration/Inputs/InputField";
import InputSimplePassword from "../../components/registration/Inputs/InputSimplePassword";
import { FormProvider, useForm } from "react-hook-form";
import InputPasswordField from "../../components/registration/Inputs/InputPasswordField";
import { toast, ToastContainer } from "react-toastify";
import { useResetUserMutation } from "../../services/registrationApi";
import { useNavigate } from "react-router-dom";

const ResetPassword = (props) => {
  const navigate = useNavigate();

  const [guid, setGuid] = useState("");
  const [resetUser, resetUserResult] = useResetUserMutation();

  useEffect(() => {
    console.log(props?.match?.params?.id);
    setGuid(props?.match?.params?.id);
  }, []);

  const schema = yup.object().shape({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      ),
    reEnterPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "password must match")
      .required("Required"),
  });

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    let tempObj = {
      uid: guid,
      password: data.password,
    };
    resetUser(tempObj)
      .then((response) => {
        console.log("RESET PASSWORD:56", response);
        if (response?.data && response.data.status == 200) {
          toast.success(response.data.message, { pauseOnFocusLoss: false });
          navigate("/login");
        } else {
          toast.error(response.error.data.message, { pauseOnFocusLoss: false });
        }
      })
      .catch((error) => {
        toast.error(error.message, { pauseOnFocusLoss: false });
      });
  };

  return (
    <>
      <section className="login-wrap">
        <div className="login-cell">
          <div className="login-block">
            <div className="l-logo-block text-center">
              <img src={Logo} alt="sealcon" title="sealcon" />
            </div>
            <div className="auth-form mr-t30">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="form-group cus-in-group mr-b20">
                    <InputPasswordField
                      label="Password"
                      register={methods.register("password")}
                    />
                  </div>
                  <div className="form-group cus-in-group mr-b20">
                    <InputSimplePassword
                      label="Re-Enter Password"
                      register={methods.register("reEnterPassword")}
                    />
                  </div>
                  <button type="submit" className="l-btn">
                    Change Password
                  </button>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </section>
      <DevTool control={methods.control} />
    </>
  );
};

export default ResetPassword;
