import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ErrorIcon from "@mui/icons-material/Report";
const ScanSuccess = ({ unauthacess = false }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const message = searchParams.get("message")
    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            window.history.go(1);
        };
    }, [])
    if (unauthacess) return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px", // Adjust the gap value as needed
            marginTop: "20px",
            textAlign: "center"
        }}>
            <h3 style={{fontFamily:"OpenSans-Regular"}}>
                Property Not Available
            </h3>
            <ErrorIcon style={{ fontSize: 150 }}/>
          
            <div>
                The Property you are trying to apply is not available. Please contact your agent / property if you wish to apply.
            </div>
        </div>
    );
    return (
        <>
            {/* {!unauthacess ?  */}
            <Box sx={{ flexGrow: 1 }}>
                <AppBar sx={{ backgroundColor: '#2dc3e8' }} position="static" >
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Docuverus
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            {/* :  */}
            <><br /><br /><br /></>
            {/* } */}
            <div className="verfied-wrap" style={{ width: "90vw" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="ver-mob-block">
                                <div className="mob-body success-body">
                                    <div className="success-block text-center">
                                        {/* {!unauthacess ?  */}
                                        <h5 className="mr-b20">
                                            Thank you for uploading documents!
                                        </h5>
                                        {/* : */}
                                        {/* <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                      
                                            gap: "20px", // Adjust the gap value as needed
                                            marginTop: "20px",
                                            textAlign: "center"
                                        }}>
                                              <h3>
                                                 Property Not Available
                                              </h3>
                                             
                                            
                                            </div> */}
                                        {/* } */}

                                        <div className="done-icon mr-t60">
                                            {/* {!unauthacess ?  */}
                                            <svg width="122" height="125" viewBox="0 0 122 125" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="61" cy="61.8823" r="61" fill="#DBF4FB" />
                                                <path d="M44.3833 66.0683L56.9154 78.6004L77.8022 49.3589"
                                                    stroke="#4BAA94" strokeWidth="5" strokeLinecap="round"
                                                    strokeLinejoin="round" />
                                                <circle cx="61.0964" cy="63.9793" r="58.4036" stroke="#4BAA94"
                                                    strokeWidth="5" />
                                            </svg>
                                            {/* :  */}
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                                                <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg> */}
                                            {/* } */}
                                        </div>
                                        {/* {!unauthacess ?  */}
                                        <p className="mt-4"><strong>Please return to your desktop browser and click Refresh button!</strong></p>
                                        {/* : */}
                                        {/* <p style={{ margin: 45 }}>{message ||
                                                "The Property you are trying to apply is not available. Please contact your agent / property if you wish to apply."}</p> */}
                                        {/* } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScanSuccess;